import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { programmDetail } from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import LessonsCardTab from "src/components/programmes/LessonsCardTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import ProgrammeRecordingTab from "src/components/programmes/ProgrammeRecordingTab";
import ResourcesCardTab from "src/components/programmes/ResourcesCardTab";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import StudySessionCardTabs from "src/components/programmes/StudySessionCardTabs";
import RatingCardTab from "src/components/programmes/RatingCardTab";
import ProgramSectionTabs from "src/components/programmes/ProgramSectionTabs";
import NoAccess from "src/components/GeneralComponents/NoAccess";
import ReactVideoPlayer from "src/components/_dashboard/GeneralComponents/ReactVideoPlayer";
import ProgrammeOverview from "src/components/programmes/ProgrammeOverview";
import Community from "../Community/Community";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("programme_tab_value", newValue);
  };

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.slug);
    if (result.code === 200) {
      if (result.program.program_lock_status === true) {
        navigate("/delegate-training");
      }
      let program_object = result.program;
      program_object.program_lock_status = false;
      setProgrammesDetail(program_object);
      let lessons_array = [];
      result?.lesson?.map((lesson) => {
        lessons_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.lesson_images.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setLessonsList(lessons_array);
      setResourcesList(result.program_document);
      setReviewsList(result.program_review);

      let recording_array = [];
      result.recording_list?.map((recording) => {
        recording_array.push({
          ...recording,
          card_title: recording.title,
          card_image: s3baseUrl + recording.recording_image.thumbnail_2,
          card_short_description: recording.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setRecordingList(recording_array);
      setProgramSection(result.program_section);

      let study_session_array = [];
      result.study_session?.map((study_session) => {
        study_session_array.push({
          ...study_session,
          card_title: study_session.title,
          card_image: s3baseUrl + study_session.image.thumbnail_2,
          card_short_description: study_session.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setStudySession(study_session_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_programme_content = () => {
    if (programmesDetail.program_lock_status === true) {
      return {
        video_url: programmesDetail.locked_program_info.video_url,
        detailed_description:
          programmesDetail.locked_program_info.detailed_description,
        image: programmesDetail.locked_program_info.image,
      };
    } else {
      return {
        video_url: programmesDetail.video_url,
        audio_file: s3baseUrl + programmesDetail.audio_file,
        detailed_description: programmesDetail.detailed_description,
        image: programmesDetail?.program_images?.thumbnail_1,
      };
    }
  };

  useEffect(() => {
    const tabValue = localStorage.getItem("programme_tab_value");
    setTabValue(tabValue ? parseInt(tabValue) : 0);
  }, []);

  useEffect(() => {
    getProgrammesDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABS_OPTIONS = [
    {
      title: "Overview",
      component: <ProgrammeOverview programmesDetail={programmesDetail} />,
    },
  ];

  programmesDetail.program_configration?.map((tab, index) => {
    if (tab.community_tab_status) {
      TABS_OPTIONS.push({
        title: tab.community_tab_title,
        tab_slug: "community",
        component: (
          <div className="mt-3">
            <Community list_type="program" event_id={programmesDetail._id} />
          </div>
        ),
      });
    }
  });

  TABS_OPTIONS.push({
    title: programmesDetail?.delegate_first_tab_heading
      ? programmesDetail?.delegate_first_tab_heading
      : "Getting Started",
    component: (
      <LessonsCardTab
        lessons={lessonsList}
        programLockStatus={programmesDetail.program_lock_status}
        programSlug={params.slug}
      />
    ),
  });

  programSection?.length > 0 &&
    programSection.map((Section, index) => {
      TABS_OPTIONS.push({
        title: (
          <a href={Section.button_url} className="text-muted">
            {Section.title}
          </a>
        ),
      });
    });

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <div className="d-flex align-items-center">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/delegate-training")}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <div className="d-flex justify-content-between flex-grow-1">
              <h1 className="ms-1 mb-0">
                {location.state
                  ? htmlDecode(location.state.title)
                  : programmesDetail?.title
                  ? htmlDecode(programmesDetail?.title)
                  : ""}
              </h1>
              <button
                className="small-contained-button float-end"
                onClick={() =>
                  navigate(`/delegate-training/${params.slug}/lessons`)
                }
              >
                View Modules
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesDetail;
