import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";

export default function MissionCardsList(props) {
  const { cardsList, type, copyUrl } = props;

  const navigate = useNavigate();
  const { level_id } = useParams();

  const handleDetail = (item) => {
    let path = `/mission-levels/${level_id}/mission/${item._id}`;
    if (type === "quest") {
      path = `/mission-levels/${level_id}/quest/${item._id}`;
    }
    navigate(path);
  };

  return (
    <div className="missions-list-card-box">
      <h3 className="missions-list-card-title">
        {type === "quest" ? "Quests" : "Missions"}
      </h3>
      <div className="challenge-video-card mt-3">
        <div className="row">
          {cardsList.map((mission) => {
            return (
              <div
                className="col-12 col-md-6 col-lg-4 mb-3 position-relative"
                key={mission._id}
              >
                <div className="card mission-level-card">
                  <div
                    className="mission-days"
                    onClick={() => handleDetail(mission)}
                  >
                    {mission.mission_duration} Days{" "}
                    {type === "quest" ? "Quest" : "Mission"}
                  </div>
                  <img
                    alt={mission.title}
                    src={s3baseUrl + mission.image.thumbnail_2}
                    onClick={() => handleDetail(mission)}
                  />
                  <div className="p-3 position-relative">
                    <div
                      className="copy-app-url-box"
                      onClick={() => copyUrl(mission)}
                    >
                      <Tooltip title="Copy App URL">
                        <Icon
                          icon="hugeicons:copy-link"
                          width="20"
                          height="20"
                        />
                      </Tooltip>
                    </div>
                    <div onClick={() => handleDetail(mission)}>
                      <h3 className="pe-4">{htmlDecode(mission.title)}</h3>
                      {mission.short_description && (
                        <p className="mui-custom-card-description">
                          {htmlDecode(mission.short_description)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
