import React, { useState } from "react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CoinsLeaderPopup from "./CoinsLeaderPopup";
import { htmlDecode } from "src/utils/convertHtml";
import { useNavigate, useParams } from "react-router-dom";

export default function OtherQuests({ quests, type, onClick }) {
  const [showAllPopUp, setShowAllPopUp] = useState(false);
  const navigate = useNavigate();
  const { level_id } = useParams();

  const handleDetail = (item) => {
    onClick(0);
    navigate(`/mission-levels/${level_id}/quest/${item._id}`);
  };

  return (
    <div className="card p-3 members-leader-board mt-3 mt-lg-0">
      <div className="d-flex justify-content-between align-items-baseline">
        <span className="challenge-heading unlock-color">Other Quests</span>
      </div>
      <hr />
      {quests.length > 0 ? (
        <>
          {quests.map((item, index) => {
            return (
              <div
                className={`d-flex justify-content-between align-items-center mt-2 quest-leader-board-item`}
                key={item._id}
                onClick={() => handleDetail(item)}
              >
                <div className="d-flex w-100">
                  <img
                    alt={item.title}
                    src={s3baseUrl + item.image?.thumbnail_2}
                    className="quests-leader-board-image"
                  />
                  <div className="user-name ms-2 flex-grow-1">
                    <div>{htmlDecode(item.title)}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <RecordNotFound title="Data not found" show_new_image={true} />
      )}

      <GeneralModelBox
        open={Boolean(showAllPopUp)}
        setOpen={setShowAllPopUp}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <CoinsLeaderPopup
            handleCross={() => setShowAllPopUp("")}
            type={type}
          />
        }
      />
    </div>
  );
}
