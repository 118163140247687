import { invokeApi } from "../../bl_libs/invokeApi";

export const general_live_chat_list_api = async (data, path) => {
  const requestObj = {
    path: path || `api/live_general_chat/list?page=0&limit=30`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
