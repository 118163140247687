import { invokeApi } from "../../bl_libs/invokeApi";
export const attitudeAssessmentListApi = async (page, limit, search) => {
  const requestObj = {
    path: `api/member/list/for_assessment?page=${page}&limit=${limit}&search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const missionMemberReportListApi = async (
  page,
  limit,
  search,
  include_members,
  type
) => {
  const requestObj = {
    path: `api/consultant/member/list?page=${page}&limit=${limit}&include_members=${include_members}&search_text=${
      search ? search : ""
    }&mission_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const missionMemberReportListApiV1 = async (
  page,
  limit,
  search,
  include_members,
  type,
  data
) => {
  const requestObj = {
    path: `api/consultant/member_mission/report?page=${page}&limit=${limit}&include_members=${include_members}&search_text=${
      search ? search : ""
    }&mission_type=${type}`,
    // path: `api/consultant/member_missions/list?page=${page}&limit=${limit}&include_members=${include_members}&search_text=${
    //   search ? search : ""
    // }&mission_type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const missionListApi = async (id, type) => {
  const requestObj = {
    path: `api/consultant/user_completed_missions/list/${id}?mission_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const missionDetailListApi = async (data) => {
  const requestObj = {
    path: `api/mission/report/for_delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const attitudeMemberAssessmentListApi = async (id, page, limit) => {
  const requestObj = {
    path: `api/member/member_assessment_list/${id}/?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const dailyDynamiteMemberAssessmentListApi = async (id, page, limit) => {
  const requestObj = {
    path: `api/dynamite_diary/list_member_diaries_for_admin/${id}/?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AssessmentDetailListApi = async (data) => {
  const requestObj = {
    path: `api/attitude_assessment_question/list_assesment_intenal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addAssessmentListApi = async (data) => {
  const requestObj = {
    path: `api/attitude_assessment_question/add_intenal_noteassessment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editAssessmentListApi = async (data) => {
  const requestObj = {
    path: `api/attitude_assessment_question/edit_intenal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteAssessmentListApi = async (data) => {
  const requestObj = {
    path: `api/attitude_assessment_question/delete_intenal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_consultant_missions_list = async () => {
  const requestObj = {
    path: `api/consultant/missions/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
