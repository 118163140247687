import React from "react";
import { s3baseUrl } from "src/config/config";

export default function ReviewComment({ comment }) {
  const { message, review_info, image } = comment;
  return (
    <>
      <div
        className="review-comment-message"
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      ></div>
      {image?.thumbnail_1 && (
        <a href={s3baseUrl + image.thumbnail_1} target="_blank">
          <img src={s3baseUrl + image.thumbnail_1} alt={message} />
        </a>
      )}
      <hr />

      {review_info?.reason && (
        <div className="review-reason-popup">
          <h3>Review Reason</h3>
          <p>{review_info?.reason}</p>
        </div>
      )}
    </>
  );
}
