import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { _get_consultant_missions_list } from "src/DAL/AssessmentApi/assessmentApi";

export default function InprogressMissionsReportFilter({
  filterData,
  setFilterData,
  handleChangeOthers,
  searchSubmitFilter,
  handleClearFilter,
}) {
  const [missionList, setMissionList] = useState([]);

  const getDataList = async () => {
    let result = await _get_consultant_missions_list();
    if (result.code == 200) {
      let mission_list = result.missions.map((mission) => {
        return {
          ...mission,
          chip_label: mission.title,
          chip_value: mission._id,
        };
      });
      setMissionList(mission_list);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleChangeMission = (value) => {
    let duration = parseInt(value?.mission_duration);
    setFilterData((values) => ({
      ...values,
      from_day: 1,
      to_day: duration || 1,
      mission_select: value,
    }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (filterData?.mission_select) {
      let duration = filterData?.mission_select?.mission_duration;

      if (name === "from_day") {
        if (value <= duration && value <= parseInt(filterData?.to_day)) {
          handleChangeOthers(value == 0 ? "" : value, [name]);
        }

        if (!filterData?.to_day || filterData?.to_day == "") {
          handleChangeOthers(duration, "to_day");
        }
      }

      if (name === "to_day" && value <= duration) {
        if (value <= duration) {
          handleChangeOthers(value == 0 ? "" : value, [name]);
        }

        if (!filterData?.from_day || filterData?.from_day == "") {
          handleChangeOthers(1, "from_day");
        }
      }
    } else {
      setFilterData((values) => ({ ...values, [name]: value }));
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  let duration = +filterData.mission_select?.mission_duration;

  return (
    <div className="container new-memories px-4">
      <form className="row" onSubmit={searchSubmitFilter}>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Missions"
            selectedOption={filterData.mission_select}
            setSelectedOption={handleChangeMission}
            optionsList={missionList}
            name="title"
            required
          />
        </div>
        {filterData.mission_select && (
          <>
            {duration > 0 && (
              <div className="col-12 mt-3">
                <div className="mission_duration_label_in_report">
                  Duration: From 1 to {duration}
                </div>
              </div>
            )}

            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <TextField
                id="outlined-basic"
                label="From Day"
                variant="outlined"
                fullWidth
                required
                name="from_day"
                value={filterData.from_day}
                onChange={handleChange}
                type="number"
                inputProps={{
                  min: 1,
                  max: duration,
                }}
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <TextField
                id="outlined-basic"
                label="To Day"
                variant="outlined"
                fullWidth
                required
                name="to_day"
                value={filterData.to_day}
                onChange={handleChange}
                type="number"
                inputProps={{
                  min: filterData?.from_day,
                  max: duration,
                }}
              />
            </div>
          </>
        )}

        <div className="col-12">
          <Box sx={{ py: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
        </div>
        <div className="col-12">
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            className="mb-3"
            onClick={handleClearFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </div>
      </form>
    </div>
  );
}
