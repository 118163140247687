import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  attchment_icon,
  communityHeart,
  emoji_picker_icon,
  send_chat_icon,
} from "src/assets";
import { _get_live_chat_list_chat } from "src/DAL/Inception/Inception";
import { useSnackbar } from "notistack";
import { update_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  get_short_string,
  handle_change_page,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import { general_live_chat_list_api } from "src/DAL/GeneralLiveChat/GeneralLiveChat";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import RecordNotFound from "src/components/RecordNotFound";
import ShowEventCommentLikes from "src/pages/LiveEvents/ShowEventCommentLikes";

const bottom_in = {
  hidden: {
    y: "100px",
    x: "-50%",
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: "0",
    transition: { delay: 0, duration: 1 },
  },
};

const scroll_to_bottom = (smooth) => {
  const chat_box = document.getElementsByClassName("live-comments-list")[0];
  const scroll_height = chat_box?.scrollHeight;
  if (smooth) {
    chat_box?.scrollBy({ top: scroll_height, behavior: "smooth" });
  } else {
    chat_box?.scrollBy({ top: scroll_height });
  }
};

const get_video_height = () => {
  if (document.getElementById("video-col")) {
    const height = document.getElementById("video-col").firstChild.offsetHeight;
    if (height > 300) {
      return height;
    } else {
      return 500;
    }
  } else {
    return 500;
  }
};

const handleScrollListener = (setNewChat, setShowScrollArrow) => {
  var scrollTimeout;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(function () {
    const chat_box = document.getElementsByClassName("live-comments-list")[0];
    const scroll_top = chat_box?.scrollTop;
    const scroll_height = chat_box?.scrollHeight;
    const offset_height = chat_box?.offsetHeight;
    const available_scroll = scroll_height - offset_height - 100;
    if (scroll_top > available_scroll) {
      setNewChat(false);
      setShowScrollArrow(false);
    } else {
      setShowScrollArrow(true);
    }
  }, 100);
};

const ShowIsLiked = (props) => {
  const { handleCommentAction, chat, handleOpenLikes, parent_message } = props;

  let action_type = "like";
  if (chat.is_liked) {
    action_type = "unlike";
  }

  return (
    <div className="d-flex">
      <div
        className="comment-like action-box px-0"
        onClick={() => {
          handleCommentAction({ comment: chat, parent_message, action_type });
        }}
      >
        {chat.is_liked ? (
          <>
            <img src={communityHeart} alt="" />{" "}
          </>
        ) : (
          <>
            <FavoriteBorderIcon />{" "}
          </>
        )}
      </div>
      <div className="comment-like action-box">
        {chat.like_count > 0 ? (
          <>
            {chat.like_count}{" "}
            <span
              onClick={() => {
                handleOpenLikes(chat);
              }}
            >
              {" "}
              {chat.like_count > 1 ? "Likes" : "Like"}
            </span>
          </>
        ) : (
          <> Like</>
        )}
      </div>
    </div>
  );
};

const HandleSingleComment = (props) => {
  const {
    chat,
    adminTimeZone,
    userInfo,
    handleOpenReplyBox,
    handleCommentAction,
    handleOpenLikes,
    comment_is_self,
    MENU_OPTIONS,
    parent_message,
  } = props;

  return (
    <>
      <div className="profile-comments inception p-2 mb-1 pb-1">
        <div className="d-flex position-relative">
          <div className="poster-name-image d-flex w-100">
            <div
              className="MuiAvatar-root MuiAvatar-circular css-1r9d7mk"
              style={{ width: 30, height: 30 }}
            >
              <Avatar
                src={s3baseUrl + chat.member.profile_image}
                alt="photoURL"
                style={{ width: 30, height: 30 }}
              ></Avatar>
            </div>
            <div className="w-100">
              <div>
                <div className="d-flex justify-content-between align-items-center set-title-width poster-name ps-1 w-100">
                  <Tooltip
                    title={chat.member.first_name + " " + chat.member.last_name}
                  >
                    <span className="card-title mb-0 fs-12 fw-500">
                      {get_short_string(
                        chat.member.first_name + " " + chat.member.last_name,
                        12
                      )}
                    </span>
                  </Tooltip>
                  <span
                    className={`action-date-time ${
                      comment_is_self ? "pe-4" : ""
                    }`}
                  >
                    {TIME_ZONE_CONVERSION(
                      chat.action_date_time,
                      "DD MMM YYYY [at] hh:mm A",
                      adminTimeZone,
                      userInfo.time_zone
                    )}
                  </span>
                </div>
                <div className="ps-1 pt-0 mt-0 live-event-comment-message live-chat-comment-message">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: urlify(htmlDecode(chat?.message)),
                    }}
                    className="text-start mb-0"
                  ></p>
                </div>
                <div className="col-12">
                  {chat.file_url && (
                    <a
                      href={s3baseUrl + chat.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="chat-image-preview mt-2">
                        <img src={s3baseUrl + chat.file_url} />
                      </span>
                    </a>
                  )}
                </div>
              </div>
              {!chat.is_featured && (
                <div className="like-reply-inception-box align-items-center ps-1">
                  {parent_message ? (
                    <div></div>
                  ) : (
                    <div
                      className="comment-reply action-box ps-0"
                      onClick={() => {
                        handleOpenReplyBox(chat);
                      }}
                    >
                      <ChatBubbleOutlineIcon /> Reply
                    </div>
                  )}
                  <ShowIsLiked
                    handleCommentAction={handleCommentAction}
                    chat={chat}
                    handleOpenLikes={handleOpenLikes}
                    parent_message={parent_message}
                  />
                </div>
              )}
            </div>
          </div>
          {comment_is_self && (
            <div className="affirmation-dots">
              <CustomPopover menu={MENU_OPTIONS} data={chat} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default function GeneralLiveChat(props) {
  const {
    module_id,
    banner_data = {},
    setIsLoadingPage = () => {},
    containerProps = {},
    chat_created_for = "meeting",
    handle_pin,
  } = props;
  const inputRef = useRef();
  const observer = useRef();
  const { socket, userInfo, adminTimeZone } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [chatMessage, setChatMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [parentMessage, setParentMessage] = useState(null);
  const [selectedObject, setSelectedObject] = useState({});
  const [bannerData, setBannerData] = useState(banner_data);
  const [chatComments, setChatComments] = useState([]);
  const [chatPinnedComments, setChatPinnedComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [image, setImage] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [previews, setPreviews] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMore, setLoadMore] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const get_live_chat_list = async (path) => {
    const postData = {
      user_id: userInfo._id,
      module_id: module_id,
    };
    const result = await general_live_chat_list_api(postData, path);
    if (result.code === 200) {
      setChatComments((old) => [...result.chat_list, ...old]);
      if (handle_pin) {
        setChatPinnedComments((old) => [...result.featured_chat, ...old]);
      }
      setLoadMore(result.load_more);
      setTotalPages(result.total_pages);
      setPageNumber((old) => old + 1);
      setIsLoading(false);
      setTimeout(() => {
        scroll_to_bottom();
      }, 200);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
    setOldImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setShowEmojiPicker(false);
    setParentMessage(null);
    setSelectedObject({});
    setFormType("ADD");
  };

  const handleOpenReplyBox = (chat) => {
    setParentMessage(chat);
    inputRef.current.focus();
  };

  const UploadImages = async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", 600);
    formData.append("height", 700);
    const result = await update_image_on_s3_for_chat(formData);
    return result.image_path;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    let file_url = "";
    if (image) {
      file_url = await UploadImages(image);
    }
    const message = chatMessage;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = {
      module_id,
      message,
      file_url,
      member,
      action_by: "consultant_user",
      chat_created_for: chat_created_for,
    };

    if (parentMessage) {
      socket_object.parent_message = parentMessage._id;
    }
    console.log(socket_object, "socket_objectsocket_object");
    socket.emit("send_live_chat_message", socket_object);
    handleRemoveAll();
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    let file_url = oldImage;
    if (image) {
      file_url = await UploadImages(image);
    }
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };
    const comment_id = selectedObject._id;
    const message = chatMessage;
    let socket_object = { comment_id, message, file_url, member };
    console.log(socket_object, "update_live_chat_message");
    socket.emit("update_live_chat_message", socket_object);
    handleRemoveAll();
  };

  const handleDelete = () => {
    setOpenDelete(false);
    const socket_object = {
      comment_id: selectedObject._id,
      module_id: module_id,
    };
    console.log(socket_object, "delete_live_chat_message");
    socket.emit("delete_live_chat_message", socket_object);
  };

  const handleCommentAction = (props) => {
    const { comment, parent_message, action_type } = props;
    const comment_id = comment._id;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = { module_id, member, comment_id, action_type };
    if (parent_message) {
      socket_object.parent_message = parent_message._id;
    }
    console.log(socket_object, "action_live_chat_message");
    socket.emit("action_live_chat_message", socket_object);
  };

  const handleCommentEdit = (comment) => {
    setSelectedObject(comment);
    setChatMessage(comment.message);
    setOldImage(comment.file_url);
    setFormType("EDIT");
  };

  const handleCommentDelete = (comment) => {
    setSelectedObject(comment);
    setOpenDelete(true);
  };

  const handleOpenLikes = (chat) => {
    setSelectedObject(chat);
    setOpenLikesModal(true);
  };

  const handleEmojiSelect = (emoji) => {
    setChatMessage((msg) => msg + emoji.native);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!isLoading && !isLoadingMore) {
            loadMoreData(loadMore);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMore, observer, chatComments, isLoading]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    get_live_chat_list(loadMore);
  };

  const handleMenu = (comment, action) => {
    let MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleCommentEdit,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleCommentDelete,
      },
    ];
    if (action && handle_pin) {
      MENU_OPTIONS.push({
        label: action === "pin" ? "Pin" : "Unpin",
        icon: "bi:pin-angle-fill",
        handleClick: () =>
          handleCommentAction({ comment, action_type: action }),
      });
    }
    return MENU_OPTIONS;
  };

  const send_live_chat_message_receiver = (data) => {
    if (data.parent_message) {
      setChatComments((old) =>
        old.map((item) => {
          let replies = [];
          if (item.replies) {
            replies = [...item.replies];
          }
          if (item._id == data.parent_message) {
            replies = [...replies, data.comment_id];
          }
          return { ...item, replies };
        })
      );
    } else {
      setChatComments((old) => [...old, data.comment_id]);
    }
    setTimeout(() => {
      scroll_to_bottom(true);
    }, 100);
  };

  const update_live_chat_message_receiver = (data) => {
    setChatComments((chats) =>
      chats.map((item) => {
        if (item._id == data.comment_id.parent_message) {
          item.replies.map((child) => {
            if (child._id == data.comment_id._id) {
              child.message = data.message;
              child.file_url = data.comment_id.file_url;
            }
            return child;
          });
        } else if (item._id == data.comment_id._id) {
          item.message = data.message;
          item.file_url = data.comment_id.file_url;
        }
        return item;
      })
    );
  };

  const delete_live_chat_message_receiver = (data) => {
    setChatComments((chats) =>
      chats.filter((item) => item._id !== data.comment_id)
    );
    setChatComments((chats) =>
      chats.map((comment) => {
        const array_filtered = comment.replies.filter(
          (child) => child._id !== data.comment_id
        );
        return { ...comment, replies: array_filtered };
      })
    );
  };

  const purchase_link_enabled_receiver = (data) => {
    if (module_id == data._id) {
      setBannerData(data);
    }
  };

  const handle_update_like = (data) => {
    const { like_count } = data;
    let is_liked = false;
    if (data.action == "like") {
      is_liked = true;
    }
    setChatComments((old) =>
      old.map((comment) => {
        if (data.parent_message) {
          let replies = [...comment.replies];
          replies = replies.map((child) => {
            if (child._id == data.comment_id) {
              child.like_count = like_count;
              if (data.member._id == userInfo._id) {
                child.is_liked = is_liked;
              }
            }
            return child;
          });
          comment = { ...comment, replies };
        } else {
          if (comment._id == data.comment_id) {
            comment.like_count = like_count;
            if (data.member._id == userInfo._id) {
              comment.is_liked = is_liked;
            }
          }
        }
        return comment;
      })
    );
  };

  const handle_update_pin_unpin = (data) => {
    const { action, comment_id } = data;
    let is_liked = false;
    if (comment_id.likes.length > 0) {
      is_liked = comment_id.likes.find((like) => like._id === userInfo._id);
    }

    if (action === "pin") {
      setChatComments((old) =>
        old.filter((chat) => chat._id !== comment_id._id)
      );
      setChatPinnedComments((old) => [...old, comment_id]);
    } else {
      setChatPinnedComments((old) =>
        old.filter((chat) => chat._id !== comment_id._id)
      );
      setChatComments((old) => [...old, { ...comment_id, is_liked }]);
    }
  };

  const action_live_chat_message_receiver = (data) => {
    const { action } = data;
    if (action === "pin" || action === "unpin") {
      handle_update_pin_unpin(data);
    } else {
      handle_update_like(data);
    }
  };

  useEffect(() => {
    if (module_id) {
      handleRemoveAll();
      setBannerData(banner_data);
      get_live_chat_list();
      // event handling
      socket.emit("general_live_chat", module_id);
      socket.on("send_live_chat_message_receiver", (data) => {
        console.log(data, "send_live_chat_message_receiver");
        send_live_chat_message_receiver(data);
      });
      socket.on("purchase_link_enabled_receiver", (data) => {
        console.log(data, "purchase_link_enabled_receiver");
        purchase_link_enabled_receiver(data);
      });
      socket.on("update_live_chat_message_receiver", (data) => {
        console.log(data, "update_live_chat_message_receiver");
        update_live_chat_message_receiver(data);
      });
      socket.on("delete_live_chat_message_receiver", (data) => {
        console.log(data, "delete_live_chat_message_receiver");
        delete_live_chat_message_receiver(data);
      });
      socket.on("action_live_chat_message_receiver", (data) => {
        console.log(data, "action_live_chat_message_receiver");
        action_live_chat_message_receiver(data);
      });
    }
    return () => {
      socket.off("send_live_chat_message_receiver");
      socket.off("update_live_chat_message_receiver");
      socket.off("delete_live_chat_message_receiver");
      socket.off("action_live_chat_message_receiver");
      socket.off("purchase_link_enabled_receiver");
    };
  }, [module_id]);

  useEffect(() => {
    setTimeout(() => {
      let pinned_element = document.getElementById("pinned-comments-list");
      let comments_element = document.getElementById("live-comments-list");
      if (pinned_element && comments_element) {
        const pinned_height = pinned_element.offsetHeight;
        const comments_height = `${get_video_height() - +pinned_height}px`;
        comments_element.style.height = comments_height;
      }
    }, 100);
  }, [bannerData, chatPinnedComments, isLoading]);

  const commentProps = {
    adminTimeZone,
    userInfo,
    handleOpenReplyBox,
    handleCommentAction,
    handleOpenLikes,
  };

  return (
    <>
      <div className="col-12 col-lg-4 mt-4 mt-lg-0 mb-3" {...containerProps}>
        <Card
          style={{
            height: get_video_height() + 10,
          }}
          className={`live-comments`}
        >
          <>
            {isLoading ? (
              <div className="live-chat-loader-box">
                <CircularProgress
                  className="live-chat-loader"
                  color="primary"
                />
              </div>
            ) : chatComments.length === 0 ? (
              <div className="live-chat-loader-box">
                <RecordNotFound title="Chat" />
              </div>
            ) : (
              <>
                <div
                  className={`pinned-comments-list ${
                    bannerData.is_purchase_link || chatPinnedComments.length > 0
                      ? "general-live-chat-list"
                      : ""
                  }`}
                  id="pinned-comments-list"
                >
                  {bannerData.is_purchase_link
                    ? bannerData.link_image &&
                      bannerData.link && (
                        <>
                          <img
                            src={s3baseUrl + bannerData.link_image}
                            width="100%"
                            onClick={() =>
                              handle_change_page(
                                bannerData.link,
                                setIsLoadingPage
                              )
                            }
                          />
                        </>
                      )
                    : chatPinnedComments.length > 0 && (
                        <>
                          {chatPinnedComments.map((chat) => {
                            let comment_is_self = Boolean(
                              chat.member._id == userInfo._id
                            );
                            return (
                              <div>
                                <HandleSingleComment
                                  {...commentProps}
                                  chat={chat}
                                  comment_is_self={comment_is_self}
                                  MENU_OPTIONS={handleMenu(chat, "unpin")}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                </div>

                <div
                  className="live-comments-list general-live-chat-list"
                  id="live-comments-list"
                  onScroll={() => {
                    handleScrollListener(setNewChat, setShowScrollArrow);
                  }}
                  style={{
                    paddingBottom: 135,
                    height: get_video_height(),
                  }}
                >
                  {totalPages > pageNumber && (
                    <div className="text-center">
                      {isLoadingMore ? (
                        <CircularProgress
                          className="mt-3"
                          style={{ width: 20, height: 20 }}
                          color="primary"
                        />
                      ) : (
                        <span
                          ref={lastBookElementRef}
                          onClick={loadMoreData}
                          id="load-more-messages"
                        >
                          <CircularProgress
                            className="mt-3"
                            onClick={loadMoreData}
                            style={{ width: 20, height: 20 }}
                            color="primary"
                          />
                        </span>
                      )}
                    </div>
                  )}
                  {chatComments.map((chat) => {
                    let comment_is_self = Boolean(
                      chat.member._id == userInfo._id
                    );
                    return (
                      <div>
                        <HandleSingleComment
                          {...commentProps}
                          chat={chat}
                          comment_is_self={comment_is_self}
                          MENU_OPTIONS={handleMenu(chat, "pin")}
                        />
                        {chat.replies?.length > 0 && (
                          <div>
                            {chat.replies.map((child) => {
                              let child_is_self = Boolean(
                                child.member._id == userInfo._id
                              );
                              return (
                                <div className="ps-3">
                                  <HandleSingleComment
                                    {...commentProps}
                                    chat={child}
                                    comment_is_self={child_is_self}
                                    MENU_OPTIONS={handleMenu(chat)}
                                    parent_message={chat}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {showScrollArrow && !newChat && (
                    <motion.div
                      variants={bottom_in}
                      initial="hidden"
                      animate={"visible"}
                      exit="exit"
                      onClick={scroll_to_bottom}
                      className="new-chat-chip"
                    >
                      <span>Scroll to bottom</span>{" "}
                      <Icon icon="akar-icons:arrow-down"></Icon>
                    </motion.div>
                  )}
                </div>
              </>
            )}
            <div id="bottom-add-comment-id" className="bottom-add-comment">
              <form>
                <div className="add-event-comment">
                  <div className="add-event-comment-text-field">
                    <TextField
                      inputRef={inputRef}
                      // ref={inputRef}
                      // className="mt-2 inputs-fields inception-input"
                      className="mt-2 inception-input"
                      id="outlined-multiline-static"
                      label={
                        parentMessage ? "Write a reply…" : "Write a comment…"
                      }
                      multiline
                      rows={2}
                      name="description"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required={true}
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                    />
                    {(formType === "EDIT" || parentMessage) && (
                      <IconButton
                        className="comment-cancel-update"
                        onClick={handleRemoveAll}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-start mt-3 mb-2 event-chat-emoji-picker-button">
                      <img
                        src={emoji_picker_icon}
                        onClick={() => {
                          setShowEmojiPicker((prevState) => !prevState);
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      {(previews || oldImage) && (
                        <span className="chat-image-preview mt-2 me-auto">
                          <span onClick={handleRemove}>x</span>
                          <img
                            src={previews ? previews : s3baseUrl + oldImage}
                          />
                        </span>
                      )}
                      <div className="mt-2 d-flex">
                        <span className="event-chat-upload-button mt-2">
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            id="event-chat-upload-button"
                            name="affirmationImage"
                            style={{ display: "none" }}
                            onChange={handleUpload}
                          />
                          <label htmlFor="event-chat-upload-button">
                            <img src={attchment_icon} alt="" srcSet="" />
                          </label>
                        </span>
                      </div>

                      {chatMessage ? (
                        <div
                          className="text-end mt-3 ms-1 mb-2 event-chat-send-button"
                          onClick={
                            !isSubmitting && formType === "ADD"
                              ? handleSendMessage
                              : handleMessageUpdate
                          }
                        >
                          <img src={send_chat_icon} alt="" srcSet="" />
                        </div>
                      ) : (
                        <Tooltip title="Write a comment">
                          <div className="text-end mt-3 ms-1 mb-2 event-chat-send-button">
                            <img src={send_chat_icon} alt="" srcSet="" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        </Card>
        {showEmojiPicker && (
          <div className="col-12  mt-4 mt-lg-0 ms-auto position-relative emoji-picker-box">
            <Picker
              data={data}
              onEmojiSelect={handleEmojiSelect}
              theme="dark"
              dynamicWidth={true}
            />
          </div>
        )}
      </div>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete live comment?"}
        handleAgree={handleDelete}
      />
      {openLikesModal && (
        <ShowEventCommentLikes
          open={openLikesModal}
          setOpen={setOpenLikesModal}
          comment_id={selectedObject._id}
        />
      )}
    </>
  );
}
