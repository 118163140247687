import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { dd_date_time_format } from "src/utils/constants";
import {
  approve_comment_api,
  delete_comment_api,
  get_review_comments_api,
} from "src/DAL/Community/Comments";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ReviewComment from "./components/ReviewComment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ReviewComments() {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [commentsList, setCommentsList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const handleCommentDetail = (data) => {
    setSelectedObject(data);
    setOpenDetailModal(true);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "name",
      label: "Name",
      handleClick: handleCommentDetail,
      className: "cursor-pointer",
    },
    {
      id: "comment_description",
      label: "Description",
      handleClick: handleCommentDetail,
      className: "cursor-pointer",
    },
    {
      id: "view_detail",
      label: "View Detail",
      className: "view-review-feed",
      handleClick: handleCommentDetail,
    },
    {
      id: "review_reason",
      label: "Reason",
      handleClick: handleCommentDetail,
      className: "cursor-pointer",
    },
    {
      id: "created_at",
      label: "Created At",
    },
  ];

  const getCommentsList = async () => {
    setIsLoading(true);
    const result = await get_review_comments_api(page, rowsPerPage, searchText);
    if (result.code === 200) {
      const comments = result.comments.map((comment, index) => {
        let review_reason = comment.review_info?.reason;
        if (review_reason?.length > 50) {
          review_reason = review_reason.substring(0, 50) + "...";
        }
        let comment_description = comment.message;
        if (comment_description?.length > 50) {
          comment_description = comment_description.substring(0, 50) + "...";
        }

        return {
          ...comment,
          name: comment.user_info_action_for?.name,
          review_reason,
          created_at: dd_date_time_format(comment.createdAt),
          view_detail: "View Detail",
          comment_description,
          table_avatar: {
            src: comment?.user_info_action_for?.profile_image
              ? s3baseUrl + comment.user_info_action_for?.profile_image
              : "",
            alt: comment.user_info_action_for.name,
          },
        };
      });
      setCommentsList(comments);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("member_rows", event.target.value);
    localStorage.setItem("member_page_number", 0);
    setPageCount(1);
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenConfirmation("delete");
  };

  const handleAgreePublish = (value) => {
    setSelectedObject(value);
    setOpenConfirmation("approve");
  };

  const handleUpdateCommentStatus = async () => {
    let result;
    if (openConfirmation === "delete") {
      setOpenConfirmation("");
      result = await delete_comment_api(selectedObject._id);
    } else {
      setOpenConfirmation("");
      result = await approve_comment_api(selectedObject._id);
    }

    if (result.code === 200) {
      setCommentsList((old) =>
        old.filter((comment) => comment._id !== selectedObject._id)
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Approve",
      icon: "ix:to-be-published",
      handleClick: handleAgreePublish,
    },
  ];

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getCommentsList();
  };

  useEffect(() => {
    getCommentsList();
  }, [page, rowsPerPage]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Review Comments</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={commentsList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
      <CustomConfirmation
        open={Boolean(openConfirmation)}
        setOpen={setOpenConfirmation}
        title={`Are you sure you want to ${openConfirmation} this comment?`}
        handleAgree={handleUpdateCommentStatus}
      />
      <GeneralPopUpModel
        open={openDetailModal}
        setOpen={setOpenDetailModal}
        title={"Review Comment"}
        componentToPassDown={<ReviewComment comment={selectedObject} />}
      />
    </>
  );
}
