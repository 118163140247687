import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { get_feeds_detail } from "src/DAL/Community/Community";

import FeedDetailPopup from "./FeedDetailPopup";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function PostDetails({ feeds_type, list_type }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const [feedDetail, setFeedDetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [feedAppearBy, setFeedAppearBy] = useState("public");

  const getFeedsDeteil = async () => {
    setIsLoading(true);
    const result = await get_feeds_detail(params.post_id);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSuccessDelete = (post) => {
    if (pathname) {
      var parts = pathname.split("/feed-detail/");
      if (parts.length > 1) {
        navigate(parts[0]);
      }
    }
  };

  const handleSuccessUpdate = (post) => {
    setIsLoading(true);
    getFeedsDeteil();
  };

  const handleChangePage = () => {
    if (pathname) {
      var parts = pathname.split("/feed-detail/");
      if (parts.length > 1) {
        navigate(parts[0]);
      }
    }
  };

  useEffect(() => {
    getFeedsDeteil();
    window.scroll(0, 0);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <IconButton className="back-screen-button" onClick={handleChangePage}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <FeedDetailPopup
        post_id={params.post_id}
        feed={feedDetail}
        getFeedsListingOnAddFeed={getFeedsDeteil}
        handleSuccessDelete={handleSuccessDelete}
        handleSuccessUpdate={handleSuccessUpdate}
        feeds_type={feeds_type}
        event_id={params.event_id}
        is_detail_page={true}
        list_type={list_type}
        feedAppearBy={feedAppearBy}
        setFeedAppearBy={setFeedAppearBy}
      />
    </>
  );
}

export default PostDetails;
