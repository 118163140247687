import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { GroupAndMemberListing } from "src/DAL/Groups/Groups";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";
import moment from "moment";
import { AddPods } from "src/DAL/Pods/Pods";
import { makeStyles } from "@mui/styles";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { get_root_value } from "src/utils/domUtils";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

export default function AddRooms() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { isAccess } = useContentSetting();
  const [personName, setPersonName] = useState([]);
  const [excludeMembers, setExcludeMembers] = useState([]);
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [selectedExclude, setSelectedExclude] = useState([]);
  const [image, setImage] = useState({});
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const [startDateMin, setStartDateMin] = useState(new Date());
  const [endDateMin, setEndDateMin] = useState(new Date());
  const [endDateMax, setEndDateMax] = useState(new Date());

  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    room_type: "general",
    image: {},
    short_description: "",
    detailed_description: "",
    zoom_link: "",
    password: "",
    start_date: new Date(),
    start_time: "00:00",
    end_date: new Date(),
    duration_hour: 1,
    duration_minute: 0,
    is_recurring: false,
    recurring_type: "weekly",
    weekdays: [],
    // badge_levels: [],
    groups: [],
  });

  const fileChangedHandler = (e) => {
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const getGroupsAndMembers = async () => {
    setIsLoading(true);
    const result = await GroupAndMemberListing();
    if (result.code === 200) {
      setGroupListing(result.groups);
      let members = result.members.map((item) => {
        return {
          ...item,
          full_name:
            item.first_name + " " + item.last_name + " (" + item.email + ")",
        };
      });
      setPersonName(members);
      setExcludeMembers(members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.end_date > endDateMax) {
      enqueueSnackbar("End date should be greater than max date", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);
    let group_array = inputs.groups.map((group) => ({
      group_slug: group.group_slug,
    }));
    let selected_member_array = [];
    let selected_member_object = {};
    member.map((member) => {
      selected_member_object = {
        member_id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });

    let selected_exclude_array = [];
    let selected_exclude_object = {};
    selectedExclude.map((member) => {
      selected_exclude_object = {
        _id: member._id,
      };
      selected_exclude_array.push(selected_exclude_object);
    });

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("image", inputs.image);
    formData.append("short_description", shortDescriptionCk);
    formData.append("detail_description", detailDescriptionCk);
    formData.append("status", inputs.status);
    formData.append("room_type", inputs.room_type);
    formData.append("zoom_link", inputs.zoom_link);
    formData.append("password", inputs.password);
    formData.append("is_recurring", inputs.is_recurring);
    formData.append("start_time", inputs.start_time);
    formData.append("duration_hour", inputs.duration_hour);
    formData.append("duration_minute", inputs.duration_minute);
    // formData.append("badge_levels", JSON.stringify(inputs.badge_levels));
    formData.append("weekdays", JSON.stringify(inputs.weekdays));
    formData.append(
      "start_date",
      moment(inputs.start_date).format("YYYY-MM-DD")
    );
    formData.append("group", JSON.stringify(group_array));
    if (inputs.room_type == "general") {
      formData.append("member", JSON.stringify(selected_member_array));
      formData.append(
        "exclude_members",
        JSON.stringify(selected_exclude_array)
      );
    }
    if (inputs.is_recurring === true) {
      formData.append("end_date", moment(inputs.end_date).format("YYYY-MM-DD"));
      formData.append("recurring_type", inputs.recurring_type);
    }
    const result = await AddPods(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeTime = (event) => {
    const { name, value } = event.target;

    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const get_dropdown_menu = (limit) => {
    let arr = [];
    for (let index = 0; index <= limit; index++) {
      arr.push(index);
    }

    return arr;
  };

  useEffect(() => {
    if (inputs.recurring_type === "daily") {
      let myDate = new Date(inputs.start_date);
      myDate.setDate(myDate.getDate() + 30);
      setEndDateMin(() => inputs.start_date);
      setEndDateMax(() => myDate);
    } else if (inputs.recurring_type === "weekly") {
      let myDate = new Date(inputs.start_date);
      myDate.setDate(myDate.getDate() + 168);
      setEndDateMin(() => inputs.start_date);
      setEndDateMax(() => myDate);
    } else if (inputs.recurring_type === "monthly") {
      let myDate = new Date(inputs.start_date);
      myDate.setDate(myDate.getDate() + 180);
      setEndDateMin(() => inputs.start_date);
      setEndDateMax(() => myDate);
    }
  }, [inputs.start_date, inputs.recurring_type]);

  useEffect(() => {
    getGroupsAndMembers();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">Add Pod</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Pod Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Pod Status*"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Zoom Link"
              variant="outlined"
              fullWidth
              name="zoom_link"
              value={inputs.zoom_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              fullWidth
              name="password"
              value={inputs.password}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">Recurring</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                name="is_recurring"
                value={inputs.is_recurring}
                onChange={handleChange}
                input={<OutlinedInput label="Recurring" />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4 ">
            <MUIAutocomplete
              inputLabel="Badge Levels"
              selectedOption={inputs.badge_levels}
              setSelectedOption={(v) =>
                setInputs((old) => ({ ...old, badge_levels: v }))
              }
              optionsList={isAccess.badge_levels}
              name="title"
              multiple={true}
            />
          </div> */}

          <div className="col-12 mt-4">
            <h4 className="ms-1">When</h4>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                name="startDate"
                inputFormat="dd/MM/yyyy"
                minDate={startDateMin}
                value={inputs.start_date}
                onChange={(e) => {
                  handleChangeDate("start_date", e);
                }}
                format="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="inputs-fields"
                    required={true}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
            <TextField
              variant="outlined"
              id="time"
              label="Start Time (Europe Dublin)"
              type="time"
              className="inputs-fields"
              name="start_time"
              required={true}
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={inputs.start_time}
              onChange={(e) => handleChangeTime(e)}
            />
          </div>

          <div className="col-12 mt-4">
            <h4 className="ms-1">Duration</h4>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">Hours</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                name="duration_hour"
                value={inputs.duration_hour}
                onChange={handleChange}
                input={<OutlinedInput label="Hours" />}
                MenuProps={MenuProps}
              >
                {get_dropdown_menu(24).map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-2">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">Minutes</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                name="duration_minute"
                value={inputs.duration_minute}
                onChange={handleChange}
                input={<OutlinedInput label="Minutes" />}
                MenuProps={MenuProps}
              >
                {get_dropdown_menu(60).map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {inputs.is_recurring && (
            <>
              <div className="col-12 mt-4">
                <h4 className="ms-1">Recurrence</h4>
              </div>

              <div
                className={`col-12 mt-4 mt-md-2 ${
                  inputs.recurring_type == "weekly" ? "col-md-4" : "col-md-6"
                }`}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-name-label">
                    Recurrence Type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    name="recurring_type"
                    value={inputs.recurring_type}
                    onChange={handleChange}
                    input={<OutlinedInput label="Recurrence Type" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"weekly"}>Weekly</MenuItem>
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.recurring_type == "weekly" && (
                <div className={`col-12 col-md-4 mt-4 mt-md-2`}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Day
                    </InputLabel>
                    <Select
                      multiple
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={inputs.weekdays}
                      onChange={(e) => handleChange(e)}
                      label="Select Day"
                      name="weekdays"
                      // className="inputs-fields svg-color"
                      sx={{
                        color: get_root_value("--input-text-color"),
                      }}
                    >
                      <MenuItem value={0}>Sunday</MenuItem>
                      <MenuItem value={1}>Monday</MenuItem>
                      <MenuItem value={2}>Tuesday</MenuItem>
                      <MenuItem value={3}>Wednesday</MenuItem>
                      <MenuItem value={4}>Thursday</MenuItem>
                      <MenuItem value={5}>Friday</MenuItem>
                      <MenuItem value={6}>Saturday</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              <div
                className={`col-12 mt-4 mt-md-2 ${
                  inputs.recurring_type == "weekly" ? "col-md-4" : "col-md-6"
                }`}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    name="startDate"
                    minDate={endDateMin}
                    maxDate={endDateMax}
                    value={inputs.end_date}
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      handleChangeDate("end_date", e);
                    }}
                    format="YYYY-MM-DD"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        required={true}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          {inputs.room_type == "general" && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Groups"
                  selectedOption={inputs.groups}
                  setSelectedOption={(v) =>
                    setInputs((old) => ({ ...old, groups: v }))
                  }
                  optionsList={groupListing}
                  name="title"
                  multiple={true}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Members"
                  selectedOption={member}
                  setSelectedOption={setMember}
                  optionsList={personName}
                  multiple
                  name="full_name"
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Exclude Members"
                  selectedOption={selectedExclude}
                  setSelectedOption={setSelectedExclude}
                  optionsList={excludeMembers}
                  multiple
                  name="full_name"
                />
              </div>
            </>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Logo *</p>
                <FormHelperText className="pt-0">
                  Image Size(350 X 100) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {image.length > 0 && <img src={image} height="50" />}
              </div>

              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-logo">
                  <Input
                    accept="image/*"
                    id="contained-button-file-logo"
                    multiple
                    type="file"
                    name="logo"
                    hidden
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <h4>Short Description *</h4>
            <GeneralCkeditor
              setInputs={setShortDescriptionCk}
              value={shortDescriptionCk}
              editorHeight={320}
              is_direct={true}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Detailed Description *</h4>
            <GeneralCkeditor
              setInputs={setDetailDescriptionCk}
              value={detailDescriptionCk}
              editorHeight={320}
              is_direct={true}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
