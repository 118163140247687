import { FormControlLabel, Radio, RadioGroup, Checkbox } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { manage_survey_answer_api } from "src/DAL/Community/SurveyFeed";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import CircularProgress from "@mui/material/CircularProgress";
import { useContentSetting } from "src/Hooks/ContentSettingState";

export default function SurveyFeed(props) {
  const { userInfo, adminTimeZone } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const { feed, handleUpdateFeed, handleSurveyDetail, searchFeeds } = props;
  const [isLoading, setIsLoading] = useState("");
  const { survey_info, survey_selected_options, action_info } = feed;

  const handleAnswer = async (option, question) => {
    if (survey_info.survey_status == "expired") return;
    setIsLoading(option._id);
    const postData = {
      feed_id: feed._id,
      option_id: option._id,
      question_id: question._id,
    };

    const result = await manage_survey_answer_api(postData);
    if (result.code === 200) {
      if (result.feed_obj.action_info.action_id === userInfo._id) {
        const data = {
          ...result.feed_obj,
          is_self: true,
        };
        handleUpdateFeed(data);
      } else {
        handleUpdateFeed(result.feed_obj);
      }
      setIsLoading("");
    } else {
      setIsLoading("");
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  return (
    <div className="feed-poll-box">
      <div className="p-2 pb-1">
        {survey_info.questions?.map((question) => {
          const total_votes = question.options.reduce(
            (accumulator, option) => accumulator + option.votes,
            0
          );

          return (
            <div className="poll-question-item-post mb-2">
              <div className="px-1 mb-1 question-statement">
                {htmlDecode(question.question_statement)}
              </div>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {question.options.map((option) => {
                  let answered = survey_selected_options?.find(
                    (ans) => ans._id == option._id
                  );
                  let vote_percentage =
                    total_votes > 0 ? (option.votes / total_votes) * 100 : 0;

                  let params = {
                    className: "p-1",
                    checked: Boolean(answered),
                    onClick: () => {
                      if (searchFeeds?.feed_action_type !== "reported") {
                        handleAnswer(option, question);
                      }
                    },
                  };

                  return (
                    <>
                      {survey_info.survey_status == "expired" ? (
                        <div className="ps-1">{option.text}</div>
                      ) : (
                        <FormControlLabel
                          value="other"
                          control={
                            isLoading === option._id ? (
                              <div
                                className="p-1 me-1"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <CircularProgress
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </div>
                            ) : question.is_multiple_allow ? (
                              <Checkbox {...params} />
                            ) : (
                              <Radio {...params} />
                            )
                          }
                          label={option.text}
                          className="ps-2"
                        />
                      )}

                      <div className="d-flex align-items-center mx-1">
                        <div className="text-muted">{`${Math.round(
                          vote_percentage
                        )}%`}</div>
                        <div className="poll-progress-bar m-1">
                          <div
                            className={`selected-progress-bar ${
                              survey_info.survey_status == "expired"
                                ? "poll-expired"
                                : ""
                            }`}
                            style={{ width: `${vote_percentage}%` }}
                          ></div>
                        </div>
                        <div className="text-muted">
                          {`${option.votes} ${
                            option.votes > 1 ? "votes" : "vote"
                          } `}
                        </div>
                      </div>
                    </>
                  );
                })}
              </RadioGroup>
            </div>
          );
        })}
      </div>
      <div className="poll-expires">
        {`Survey ${
          survey_info.survey_status == "expired" ? "expired" : "expires"
        } on`}
        {TIME_ZONE_CONVERSION(
          survey_info.expiry_date_time,
          " DD-MM-YYYY [at] hh:mm A",
          adminTimeZone,
          userInfo.time_zone
        )}
      </div>
      {(survey_info.survey_result !== "private" ||
        action_info.action_id == userInfo._id) && (
        <div
          className="view-poll-result text-center p-2"
          onClick={() => handleSurveyDetail(feed, "survey")}
        >
          View Details
        </div>
      )}
    </div>
  );
}
