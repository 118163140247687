import { invokeApi } from "../../bl_libs/invokeApi";

export const DelegatePodsListing = async (data) => {
  const requestObj = {
    path: `api/delegates_room/list_room_for_delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DetailPodsApi = async (pods_slug) => {
  const requestObj = {
    path: `api/delegates_room/room_detail/${pods_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pods_list_api_v1 = async (type, data, path) => {
  const requestObj = {
    path: path || `api/room/room_list_with/type/search/v1?room_type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
