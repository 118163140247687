import React from "react";
import ReactApexChart from "react-apexcharts";

export default function PieMissionChart({ item }) {
  console.log(item, "item");
  const labels = item.options.map((option) => option.option_value);
  const series = item.options.map((option) => parseFloat(option.percentage));

  const [options] = React.useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: labels,
    colors:
      item.colors && item.colors.length > 0
        ? item.colors
        : labels.map(() => {
            let color = "#000"; // Darken the color by 50%
          }),
    stroke: {
      width: 1, // Remove the white line between segments
    },
    legend: {
      show: false, // Hide the legend
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(1) + "%"; // Display percentage with one decimal place
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  // Function to darken a color
  function darkenColor(color, percent) {
    const num = parseInt(color.slice(1), 16),
      amt = Math.round(2.55 * percent * 100),
      R = (num >> 16) - amt,
      G = ((num >> 8) & 0x00ff) - amt,
      B = (num & 0x0000ff) - amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  return (
    <div className="w-100">
      <div id="chart" style={{ display: "flex", justifyContent: "center" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width={280}
        />
      </div>
      <div
        className="d-flex justify-content-start mb-3 flex-wrap for-mobile-indication mx-2"
        style={{ alignItems: "end" }}
      >
        {item.options &&
          item.options.length > 0 &&
          item.options.map((option) => (
            <span className="">
              <div
                className="d-flex align-content-center me-3"
                style={{ alignItems: "center", opacity: 1, cursor: "pointer" }}
              >
                <span
                  className="box-1  me-1"
                  style={{
                    border: `1px solid ${option.color ? option.color : "#000"}`,
                    background: option.color ? option.color : "#000",
                  }}
                />
                <span className="color-white" style={{ fontSize: "12px" }}>
                  {option.option_value}
                </span>
              </div>
            </span>
          ))}
      </div>
    </div>
  );
}
