import { invokeApi } from "../../bl_libs/invokeApi";

export const goalMemberListingApi = async (members_type) => {
  const requestObj = {
    path: `api/goal_statement_question/user_list_goal_statement_for_consultant?type=complete&members_type=${members_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const goalIncompleteMemberListingApi = async (members_type) => {
  const requestObj = {
    path: `api/goal_statement_question/user_list_goal_statement_for_consultant?type=incomplete&members_type=${members_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const goalSaveAndCloseListApi = async (members_type) => {
  const requestObj = {
    path: `api/goal_statement_question/user_list_goal_statement_save_and_close_for_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const goalSaveAndCloseListApiV1 = async (
  page,
  rowsPerPage,
  text,
  members_type
) => {
  const requestObj = {
    path: `api/goal_statement_question/user_list/goal_statement_save_and_close/pagination?page=${page}&limit=${rowsPerPage}&search_text=${
      text ? text : ""
    }&members_type=${members_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const goalSaveAndCloseAddApi = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/goal_statement_save_and_close`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const goalIncompleteApi = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/goal_statement_incomplete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const getDetailQuestion = async (id) => {
//   const requestObj = {
//     path: `api/goal_statement_question/${id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };

export const AddCommentOnQuestion = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/add_comment_on_goal_statement_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
// export const EditQuestion = async (data, id) => {
//   console.log(id, "params.id");
//   const requestObj = {
//     path: `api/goal_statement_question/consultant/${id}`,
//     method: "PUT",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
// // export const getGoalStatementQuestion = async (id) => {
// //   console.log(id, "params.id");
// //   const requestObj = {
// //     path: `api/member/get_goal_statement_by_member_id/${id}`,
// //     method: "GET",
// //     headers: {
// //       "x-sh-auth": localStorage.getItem("token"),
// //     },
// //     // postData: data,
// //   };
// //   return invokeApi(requestObj);
// // };
export const DeleteQuestionCommentApi = async (id) => {
  const requestObj = {
    path: `api/goal_statement_question/comment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const questionReplyHistory = async (data) => {
  const requestObj = {
    path: `api/member/get_answer_stat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const send_goal_statement_reminder_api = async (data) => {
  const requestObj = {
    path: `api/goal_statement_question/send_reminder`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
