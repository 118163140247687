import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  missionListApi,
  missionMemberReportListApi,
} from "src/DAL/AssessmentApi/assessmentApi";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constants";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MissionReportList({ type }) {
  const navigate = useNavigate();
  const { isAccess } = useContentSetting();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [userInfo, setUserinfo] = useState({});

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchText, setSearchText] = useState("");

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("report_member", searchText);

    getConsultantListing(searchText);
  };

  const getConsultantListing = async (text) => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const result = await missionListApi(id, type);
      if (result.code === 200) {
        console.log(
          result,
          "missionMemberReportListApimissionMemberReportListApi"
        );

        setUserinfo(result?.user_data);

        const data = result.missions.map((val, index) => {
          return {
            ...val,
            Username: val?.mission_info?.title,
            mission_completed_date: dd_date_format(val?.mission_completed_date),
            mission_start_date: dd_date_format(val?.mission_start_date),

            mission_level:
              val?.mission_info?.membership_level_info?.badge_level_info?.title,
          };
        });
        setUserList(data);
        setTotalPages(result.total_pages);
        setTotalCount(result.total_member_count);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    if (type == "completed") {
      navigate(
        `/mission-member-report/mission/mission-detail/${id}/${value?.mission_info._id}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/inprogress-mission-report/mission/mission-detail/${id}/${value?.mission_info._id}`,
        {
          state: value,
        }
      );
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("report_member");
    if (search_text) {
      setSearchText(search_text);
      getConsultantListing(search_text);
    } else {
      setSearchText("");
      getConsultantListing("");
    }
  }, [page, rowsPerPage, type]);
  const MENU_OPTIONS = [
    {
      label: "View Mission Report",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  let breadCrumbMenu = [
    {
      title: `Member ${
        userInfo ? `(${userInfo.first_name} ${userInfo.last_name})` : ""
      }`,

      navigation:
        type == "completed"
          ? `/mission-member-report`
          : "/inprogress-mission-report",
      active: false,
    },
    {
      title: type == "completed" ? "Completed Missions" : "Inprogress Missions",
      active: true,
    },
  ];
  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "Username", label: "Title" },
    { id: "mission_level", label: "Badge Level" },
    { id: "mission_duration", label: "Mission Duration (Days)" },
    { id: "mission_attracted_coins", label: "Coins Earned" },
    { id: "mission_reward_coins", label: "Achievable Coins" },
    { id: "mission_start_date", label: "Mission Start Date" },
    { id: "mission_completed_date", label: "Mission Completed Date" },
  ];
  let filteredTableHead = [];
  if (type == "in_progress") {
    filteredTableHead =
      type === "in_progress"
        ? TABLE_HEAD.slice(0, -1) // Remove the last element
        : TABLE_HEAD;
  }
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>

        <div className="col-12 mt-4">
          <CustomMUITable
            TABLE_HEAD={filteredTableHead}
            data={userList}
            selected={selected}
            MENU_OPTIONS={MENU_OPTIONS}
            setSelected={setSelected}
            className="card-with-background"
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
