import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

export default function AddEventInfo(props) {
  const { setEventInfo, eventInfo } = props;

  const handleChangeEventInfo = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setEventInfo((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      <div className="col-12">
        <p className="mb-1 mt-2">Event Title *</p>
        <GeneralCkeditor
          setInputs={setEventInfo}
          inputs={eventInfo}
          name="event_title"
          editorHeight={220}
        />
      </div>
      <div className="col-12 col-md-6">
        <TextField
          fullWidth
          className="mt-3"
          id="outlined-basic"
          label="Button Text"
          variant="outlined"
          name="button_text"
          size="small"
          value={eventInfo.button_text}
          required={true}
          onChange={handleChangeEventInfo}
        />
      </div>
      <div className="col-12 col-md-6">
        <TextField
          fullWidth
          className="mt-3"
          id="outlined-basic"
          label="Button Link"
          variant="outlined"
          name="button_link"
          size="small"
          value={eventInfo.button_link}
          required={true}
          onChange={handleChangeEventInfo}
        />
      </div>
      <div className="col-12 col-md-4">
        <TextField
          fullWidth
          className="mt-3"
          type="color"
          label="Text Color"
          variant="outlined"
          name="button_text_color"
          size="small"
          value={eventInfo.button_text_color}
          required={true}
          onChange={handleChangeEventInfo}
        />
      </div>
      <div className="col-12 col-md-4">
        <TextField
          fullWidth
          className="mt-3"
          type="color"
          label="Background Color"
          variant="outlined"
          name="button_background_color"
          size="small"
          value={eventInfo.button_background_color}
          required={true}
          onChange={handleChangeEventInfo}
        />
      </div>
      <div className="col-12 col-md-4 mt-3">
        <FormControl variant="outlined" size="small" required>
          <InputLabel id="button-alignment">Button Alignment</InputLabel>
          <Select
            labelId="button-alignment"
            id="button-alignment"
            value={eventInfo.button_alignment}
            onChange={handleChangeEventInfo}
            label="Alignment"
            name="button_alignment"
          >
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="right">Right</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
}
