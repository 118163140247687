import { invokeApi } from "../../bl_libs/invokeApi";

export const AddGroupApi = async (data) => {
  const requestObj = {
    // path: `api/group`,
    path: `api/group/add_group_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const GroupListing = async (data) => {
  const requestObj = {
    path: `api/group/active_list_by_consultant/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _group_filter_listing = async (page, limit, data) => {
  const requestObj = {
    path: `api/group/group_filter?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const GroupAndMemberListing = async (data) => {
  const requestObj = {
    path: `api/consultant/groups_and_members/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const activeAutomatedGroupList = async (data) => {
  const requestObj = {
    path: `api/group/active_automated_group_list/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AllGroupListing = async (data) => {
  const requestObj = {
    path: `api/group/consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const GroupDetailApi = async (group_slug) => {
  const requestObj = {
    path: `api/group/detail/${group_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DeleteGroup = async (group_slug) => {
  const requestObj = {
    path: `api/group/${group_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditGroupApi = async (group_slug, data) => {
  const requestObj = {
    // path: `api/group/${group_slug}`,
    path: `api/group/update_group_v1/${group_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_members_and_programs_v2 = async (data) => {
  const requestObj = {
    path: `api/member/list_members_and_programs_v2`,
    method: "POST",
    postData: data,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const all_group_members_api = async (
  slug,
  page,
  limit,
  search,
  type
) => {
  const requestObj = {
    path: `api/group/all_group_members/${slug}?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }&type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
