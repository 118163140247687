import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
export default function RewardsPopup(props) {
  const { handleCross, showInfoPopup } = props;
  return (
    <div className="unlock-video p-2 congratulation-message">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      {showInfoPopup.length > 0 && (
        <div className="container-fluid px-0">
          <div className="row g-2 mt-0 mb-1">
            {showInfoPopup.map((card, index) => {
              return (
                <div
                  key={index}
                  className="col-1 ms-2 me-2 d-flex justify-content-center mt-0"
                >
                  <Tooltip title={card.title + " " + card.no_of_badges + "x"}>
                    <img
                      src={s3baseUrl + card?.general_icon?.thumbnail_1}
                      alt=""
                      className="badge-image"
                    />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
