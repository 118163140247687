import { useLocation, useNavigate } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { _get_member_missions_and_quests_Api } from "src/DAL/member/Member";
import { show_proper_words } from "src/utils/constants";

export default function MemberMissions({ missionList }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleMissionDetail = (value) => {
    navigate(`${pathname}/${value?.mission_info?._id}`, {
      state: value,
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Title",
      handleClick: handleMissionDetail,
      className: "cursor-pointer",
    },
    {
      id: "mission_duration",
      label: "Mission Duration (Days)",
    },

    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        return (
          <span>
            {row?.mission_status
              ? show_proper_words(row?.mission_status)
              : "N/A"}
          </span>
        );
      },
    },
  ];

  console.log(pathname, "pathnamepathnamepathname");

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={missionList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
