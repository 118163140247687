import { invokeApi } from "../../bl_libs/invokeApi";

export const mission_levels_list_api_v1 = async () => {
  const requestObj = {
    path: `api/consultant/badge_level/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_delegate_missions_api = async (level_id) => {
  const requestObj = {
    path: `api/consultant/get/missions/${level_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const mission_detail_api = async (mission_id) => {
  const requestObj = {
    path: `api/consultant/mission/details/${mission_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const mission_schedule_detail_api = async (schedule_id) => {
  const requestObj = {
    path: `api/consultant/mission_schedule/details/${schedule_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_more_mission_coins_users_api = async (queryParams) => {
  const { mission_id, type, path } = queryParams;

  const requestObj = {
    path:
      path ||
      `api/consultant/mission_leaderboard/get/${mission_id}?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_source_api_link_v1 = async (data) => {
  const requestObj = {
    path: `api/member/create/branch_code_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
