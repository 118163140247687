import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { point } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { medal_1, medal_2, medal_3 } from "src/assets";
import { number_with_comma } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { get_more_mission_coins_users_api } from "src/DAL/Missions/Missions";
import { useSnackbar } from "notistack";
import moment from "moment";

export default function CoinsLeaderPopup(props) {
  const { handleCross, type, inner_type } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [leaderBoardUsers, setLeaderBoardUsers] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();

  const observer = useRef();
  const { mission_id } = useParams();

  const get_mission_detail = async (path) => {
    let queryParams = { mission_id, type, path };
    const result = await get_more_mission_coins_users_api(queryParams);
    if (result.code == 200) {
      const { streak_leader_board_stats, coins_leader_board_stats } = result;
      if (type == "mission_leaderboard") {
        setLeaderBoardUsers((old) => [...old, ...streak_leader_board_stats]);
        setLoadMorePath(result.streak_load_more);
      } else {
        setLeaderBoardUsers((old) => [...old, ...coins_leader_board_stats]);
        setLoadMorePath(result.coins_load_more);
      }
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      setIsLoadingMore(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      get_mission_detail(loadMorePath);
    }
  };

  useEffect(() => {
    get_mission_detail();
  }, []);

  return (
    <div className="unlock-video p-2 congratulation-message">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      <div className="d-flex justify-content-cetner main-heading-leader-boeard">
        {inner_type === "quest" ? (
          <span className="challenge-heading unlock-color">Quest Members</span>
        ) : (
          <span className="challenge-heading unlock-color">{`Mission ${
            type === "coins_leaderboard" ? "In Progress" : "Completed"
          }`}</span>
        )}
      </div>
      {isLoading ? (
        <div className="leader-board-loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {leaderBoardUsers?.length > 0 ? (
            leaderBoardUsers?.map((item, index) => {
              return (
                <div
                  className={`d-flex justify-content-between align-items-center mt-2 main-card-container `}
                  key={item._id}
                >
                  <div className="d-flex align-items-center">
                    <div className="me-1">
                      {inner_type === "quest" ? (
                        <span className={`${index < 9 ? "me-2" : "me-1"}`}>
                          {index + 1}.
                        </span>
                      ) : index <= 2 ? (
                        <Avatar
                          src={
                            index === 0
                              ? medal_1
                              : index === 1
                              ? medal_2
                              : medal_3
                          }
                          sx={{ width: 20, height: 20 }}
                        />
                      ) : (
                        <div>
                          <span className={`${index < 9 ? "me-2" : "me-1"}`}>
                            {index + 1}.
                          </span>
                        </div>
                      )}
                    </div>
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      alt={item.user_info?.name}
                      src={s3baseUrl + item.user_info?.profile_image}
                    />
                    <div className="user-name ms-2 d-flex align-items-center">
                      <span style={{ textAlign: "start", color: "white" }}>
                        {item.user_info?.first_name +
                          " " +
                          item.user_info?.last_name}
                      </span>
                      {type === "coins_leaderboard" && (
                        <>
                          &nbsp;
                          <span className="mission-completed-days">
                            {` (${item.completed_days}/${item.mission_duration})`}
                          </span>
                        </>
                      )}
                      {item.user_info?._id == userInfo._id && (
                        <img src={point} className={`pointer_hand`} />
                      )}
                    </div>
                  </div>
                  {inner_type === "quest" ? (
                    <div className="user-coins">
                      {moment(item.mission_start_date).format("DD MMM")} -{" "}
                      {moment(item.mission_start_date)
                        .add(item.mission_duration, "days")
                        .format("DD MMM")}
                    </div>
                  ) : (
                    <div className="user-coins">
                      {number_with_comma(item.mission_attracted_coins)}
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <>
              <RecordNotFound title="Data not found" show_new_image={true} />
            </>
          )}
          {loadMorePath ? (
            <div className="col-12 text-center">
              <button
                ref={lastBookElementRef}
                className="small-contained-button mt-3"
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
