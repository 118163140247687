import { Chip, CircularProgress, Divider, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import RecordNotFound from "src/components/RecordNotFound";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import {
  DeleteNote,
  EditNote,
  addPersonalNote,
  noteListing,
} from "src/DAL/member/Member";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";
import FullPagePopup from "src/components/GeneralComponents/FullPagePopup";
import AddCalendarEventsNew from "src/components/GeneralComponents/AddCalendarEventsNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PersonalMemberNotes({
  type,
  handleClose,
  user_id,
  is_popup,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContentSetting();
  const [inputLessonNote, setInputLessonNote] = useState({ personal_note: "" });
  const [NoteListing, setNoteListing] = useState([]);
  const [noteId, setNoteId] = useState(0);
  const classes = useStyles();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [memberData, setmemberData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [template, setTemplate] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [showButtons, setShowButtons] = useState({
    addButton: true,
    editButton: false,
  });

  if (!user_id) {
    user_id = params.id;
  }

  //Changing Textarea values with onchange
  const handleNoteChange = (event) => {
    const value = event.target.value;
    setInputLessonNote(value);
  };

  const handleMessage = (value) => {
    const messageTemplate = inputLessonNote.personal_note + value;
    setInputLessonNote((inputs) => ({
      ...inputs,
      ["personal_note"]: messageTemplate,
    }));
  };

  //Adding Note
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputLessonNote.personal_note.length == 0) {
      enqueueSnackbar("Personal note is required", { variant: "error" });
    } else {
      let postData = {
        personal_note: inputLessonNote.personal_note,
        member_id: user_id,
      };

      setIsLoading(true);
      const result = await addPersonalNote(postData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        getNoteListing();
        setIsOpenForm(false);
        setInputLessonNote((inputs) => ({
          ...inputs,
          ["personal_note"]: "",
        }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  //Getting Note In textarea
  const handleEdit = (note) => {
    console.log(note, "notenotenote");
    setInputLessonNote((inputs) => ({
      ...inputs,
      ["personal_note"]: note.note,
    }));

    setNoteId(note._id);
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
    setShowButtons({
      addButton: false,
      editButton: true,
    });

    const notes = document.getElementById("notes-input");
    setTimeout(() => {
      notes.scrollIntoView();
      notes.focus();
    }, 500);
  };

  const handleCancelUpdate = () => {
    setInputLessonNote((inputs) => ({
      ...inputs,
      ["personal_note"]: " ",
    }));
    setNoteId("");
    setIsOpenForm(false);
    setShowButtons({
      addButton: true,
      editButton: false,
    });
  };
  const handleCancelAdd = () => {
    setIsOpenForm(false);
  };

  const getNoteListing = async () => {
    setIsLoading(true);
    try {
      const result = await noteListing(user_id);
      if (result.code === 200) {
        setNoteListing(result.member.personal_note);
        setmemberData(result.member);
        setTemplate(result.auto_responder_message);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleAgreeDelete = (note) => {
    setNoteId(note._id);
    setOpenDelete(true);
  };

  //Updating Note
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (inputLessonNote.personal_note.length == 0) {
      enqueueSnackbar("Personal note is required", { variant: "error" });
    } else {
      setIsLoading(true);
      let postData = {
        personal_note: inputLessonNote.personal_note,
        member_id: user_id,
        note_id: noteId,
      };
      const result = await EditNote(postData);
      if (result.code === 200) {
        setInputLessonNote("");
        setNoteId(0);
        setShowButtons({
          addButton: true,
          editButton: false,
        });
        getNoteListing();
        setIsOpenForm(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    setIsLoading(false);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    let postData = {
      note_id: noteId,
      member_id: user_id,
    };
    const result = await DeleteNote(postData);
    if (result.code === 200) {
      getNoteListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleClick = () => {
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleBack = () => {
    if (handleClose) {
      handleClose();
      return;
    }
    if (type == "nurture_members") {
      navigate("/nurture-members");
    } else if (type == "all_members") {
      navigate("/all-members");
    } else {
      navigate("/members");
    }
  };

  useEffect(() => {
    getNoteListing();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(inputLessonNote, "inputLessonNoteinputLessonNote");

  return (
    <div className="container mb-5">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this note?"}
        handleAgree={handleDelete}
      />
      <div className="row">
        <div className="col-12 col-md-8 col-lg-9 d-flex">
          {is_popup ? (
            <h2>{`${
              memberData?.first_name + " " + memberData?.last_name
            }'s Personal Notes`}</h2>
          ) : (
            <>
              <span>
                <IconButton className="back-screen-button" onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <p className="pt-2">
                {memberData?.first_name +
                  " " +
                  memberData?.last_name +
                  " (" +
                  memberData?.email +
                  ") "}
              </p>
            </>
          )}
        </div>
        <div className="col-12 col-md-4 col-lg-3">
          <button
            className="small-contained-button float-end mt-1"
            onClick={handleClick}
          >
            Add Note
          </button>
        </div>

        {!is_popup && (
          <div className="col-6 mt-3">
            <h2>Personal Notes</h2>
          </div>
        )}
      </div>
      <div className="">
        {isLoading == true ? (
          <CircularProgress className={classes.loading} color="primary" />
        ) : (
          <div className="row">
            {NoteListing.length > 0 ? (
              NoteListing.map((note, index) => (
                <div className="col-12 section-space" key={index}>
                  <div
                    className={`card ${is_popup ? "notes-input-card" : ""}${
                      note.action_source_type
                        ? note.action_source_type === "member_user"
                          ? "incoming-message-note-card"
                          : ""
                        : ""
                    }`}
                  >
                    <div className="card-body notes-input set-display-inline">
                      {note.last_updated_date_time && (
                        <div className="updated-date">
                          <div className="d-flex">
                            <span>Last Action : </span>
                            <div>
                              <p className="lesson-notes-title ms-1 mt-0 mb-1 mt-1 date-color">
                                {get_date_with_user_time_zone(
                                  note.last_updated_date_time,
                                  "DD-MM-YYYY hh:mm A",
                                  userInfo.time_zone,
                                  userInfo.adminTimeZone
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {note.action_by == "consultant_user" && (
                        <CustomPopover menu={MENU_OPTIONS} data={note} />
                      )}
                      <div className="d-flex">
                        <p className="color mb-0 action-info-name">
                          {note.action_info?.name &&
                            note.action_info?.name +
                              " " +
                              "(" +
                              note.action_info.action_by +
                              ")"}
                        </p>

                        <p className="lesson-notes-title ms-1  mb-1 mt-1 date-color">
                          {"Created at" +
                            get_date_with_user_time_zone(
                              note.note_date_time,
                              "DD-MM-YYYY hh:mm A",
                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                        </p>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: note.note,
                        }}
                        className="mt-2 normal-text"
                      ></p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="mt-5">
                <RecordNotFound title="Notes" />
              </div>
            )}
          </div>
        )}
      </div>
      {isOpenForm && (
        <div ref={ref} className={`row text-area-container mt-3`}>
          <div className="col-12">
            <form
              onSubmit={showButtons.addButton ? handleSubmit : handleUpdate}
            >
              <div className="col-12 ">
                <p className="tracking_code_heading text-white">
                  Personal Notes{" "}
                </p>
                <GeneralCkeditor
                  setInputs={setInputLessonNote}
                  inputs={inputLessonNote}
                  name="personal_note"
                  editorHeight={320}
                />
              </div>
              <div>
                {template.length > 0 && (
                  <div className="col-12 mb-3 px-3 mt-2 esponded-messages">
                    <Divider className="mb-2" />
                    {template?.map((message) => {
                      return (
                        <Chip
                          label={message.title}
                          className="me-1 mb-1"
                          onClick={() => handleMessage(message.message)}
                        />
                      );
                    })}
                    <Divider className="mt-2" />
                  </div>
                )}
              </div>
              {showButtons.addButton === true && (
                <div className="d-flex justify-content-end">
                  <button
                    className="mt-2 me-2 small-contained-button"
                    onClick={handleCancelAdd}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="mt-2 float-end small-contained-button"
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Save"}
                  </button>
                </div>
              )}
              {showButtons.editButton === true && (
                <div className="d-flex justify-content-end">
                  <button
                    className="mt-2 me-2 small-contained-button"
                    onClick={handleCancelUpdate}
                  >
                    Cancel
                  </button>
                  <button
                    className="mt-2 small-contained-button"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              )}{" "}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
