import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useRef } from "react";
import { videoShowLock } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { dd_date_format } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

export default function MissionQuestSchedules(props) {
  const { handleClick, schedules, missionDetail, missionSchedules } = props;

  const ref = useRef(null);

  const replacedString = (string, unlock_date) => {
    if (!string) return;
    return string.replace(/{unlock_date}/g, dd_date_format(unlock_date));
  };

  return (
    <div className="row mission-levels-schedule-tab" ref={ref}>
      <div className={`${screen.width > 767 ? "card" : ""}`}>
        <div className="col-12">
          {schedules.map((schedule) => {
            const today = moment();
            const startedAt = moment(schedule.started_at);

            return (
              <div
                className="position-relative"
                key={schedule._id}
                id={schedule._id}
              >
                <div
                  className={`schedule-click  mb-b ${
                    schedule._id === missionSchedules?._id
                      ? "in-progress-schedule"
                      : ""
                  }`}
                  key={schedule.title}
                  onClick={() => handleClick(schedule)}
                >
                  <div className="row">
                    <div className="col-3">
                      <img
                        src={s3baseUrl + schedule?.image?.thumbnail_2}
                        className="img-quest"
                      />
                    </div>
                    <div className="col-9">
                      <div className="d-flex justify-content-between">
                        <h6 style={{ fontSize: "16px" }}>
                          {schedule.main_heading}
                        </h6>
                        {schedule.schedule_status === "locked" && (
                          <Tooltip
                            title={`${
                              missionDetail.mission_status === "not_started"
                                ? "Start mission before unlock schedule"
                                : startedAt.isAfter(today, "day")
                                ? `You can't unlock it before ${dd_date_format(
                                    schedule.started_at
                                  )}`
                                : "Click to unlock"
                            }`}
                          >
                            <img
                              src={videoShowLock}
                              alt=""
                              className="lock-thumbnail-icon"
                            />
                          </Tooltip>
                        )}
                      </div>
                      <p className="mb-0">{schedule.short_description}</p>
                      {missionDetail.content_settings?.unlock_date_statement &&
                        missionDetail.mission_status === "in_progress" &&
                        schedule.schedule_status == "locked" && (
                          <p
                            className="unlock-date-note"
                            style={{ fontSize: "12px" }}
                          >
                            {htmlDecode(
                              replacedString(
                                missionDetail.content_settings
                                  ?.unlock_date_statement,
                                schedule.started_at
                              )
                            )}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
