import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PollIcon from "@mui/icons-material/Poll";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Avatar, Tooltip } from "@mui/material";
import {
  add_new_feed_with_poll,
  update_feed_with_poll,
} from "src/DAL/Community/Community";
import { projectMode, s3baseUrl } from "src/config/config";
import {
  TIME_ZONE_CONVERSION,
  current_time_with_timezone,
  show_proper_words,
  string_avatar,
} from "src/utils/constants";
import CodeIcon from "@mui/icons-material/Code";
import { useNavigate } from "react-router-dom";
import { update_feed_image_on_s3 } from "src/DAL/Login/Login";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import MentionEditor from "./components/MentionEditor";
import CreatePoll from "./components/FeedPoll/CreatePoll";
import CreateSurvey from "./components/SurveyFeed/CreateSurvey";
import AddEventInfo from "./components/AddEventInfo";
import ScheduleTime from "./components/ScheduleTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};

function areOptionsUnique(optionsArray) {
  const textValues = optionsArray.map((item) => item.text);
  const uniqueTextValues = new Set(textValues);
  return uniqueTextValues.size === textValues.length;
}

function areQuestionsOptionsUnique(questionsArray) {
  return questionsArray.every((question) => areOptionsUnique(question.options));
}

export default function CreatePost({
  openCreateModal,
  setOpenCreateModal,
  setFeedType,
  feedType,
  getFeedsListing,
  handleSuccessUpdate,
  selectedFeed,
  setSelectedFeed = () => {},
  event_id,
  userType,
  list_type,
  is_scheduled,
  setWinFeedsList,
  feedAppearBy,
  setFeedAppearBy,
}) {
  const navigate = useNavigate();
  const { adminTimeZone, userInfo, isAccess, socket } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formAction, setFormAction] = useState("ADD");
  const [userLevel, setUserLevel] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [PostLevel, setPostLevel] = useState([]);
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const time_now = new Date(
    current_time_with_timezone(userInfo.time_zone, "YYYY-MM-DD hh:mm a")
  );
  let tomorrowDate = time_now;
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const [pollOptions, setPollOptions] = useState([{ text: "" }, { text: "" }]);
  const [pollInfo, setPollInfo] = useState({
    expiry_date: tomorrowDate,
    expiry_time: time_now,
    is_multiple_allow: true,
    poll_result: "public",
  });

  const [surveyQuestions, setSurveyQuestions] = useState([
    {
      question_statement: "",
      options: [{ text: "" }, { text: "" }],
      is_multiple_allow: true,
    },
  ]);
  const [surveyInfo, setSurveyInfo] = useState({
    expiry_date: tomorrowDate,
    expiry_time: time_now,
    poll_result: "public",
  });
  const [oldThumbnail, setOldThumbnail] = useState("");

  let feed_created_for = "general";
  if (list_type == "event") {
    feed_created_for = "event";
  } else if (list_type == "the_cosmos") {
    feed_created_for = "delegate";
  } else if (list_type == "program") {
    feed_created_for = "program";
  } else if (list_type == "mission") {
    feed_created_for = "mission";
  }

  const initialTime = new Date();
  initialTime.setHours(0, 0, 0, 0);

  let EMPTY_INPUTS = {
    feedAppearBy: "public",
    VideoUrl: "",
    description: "",
    LiveUrl: "",
    is_publish: Boolean(!is_scheduled),
    start_date: dayjs(new Date()).$d,
    start_time: initialTime,
  };

  let EMPTY_EVENT_INFO = {
    event_title: "",
    button_text: "",
    button_link: "",
    button_alignment: "center",
    button_background_color: "#f6bd4b",
    button_text_color: "#FFFFFF",
    is_event_info: false,
  };
  const [inputs, setInputs] = useState(EMPTY_INPUTS);
  const [eventInfo, setEventInfo] = useState(EMPTY_EVENT_INFO);

  const handleRemove = () => {
    setThumbnail("");
    setOldThumbnail("");
  };

  const handleRemoveImage = (path) => {
    setPreviews((images) => {
      return images.filter((image) => image !== path);
    });
  };

  const UploadImages = async (formData) => {
    const result = await update_feed_image_on_s3(formData);
    return result.images_obj;
  };

  const handleUpload = async (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const images_array = [];
    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };
      images_array.push(setImageObject);
    }
    setPreviews((prevFiles) => [...prevFiles, ...images_array]);
  };

  const handleUploadThumbnail = async (event) => {
    const targetFile = event.target.files[0];
    setThumbnail(targetFile);
  };

  const handleClose = () => {
    setInputs(EMPTY_INPUTS);
    setEventInfo(EMPTY_EVENT_INFO);
    setPreviews([]);
    setPostLevel([]);
    setSurveyQuestions([
      {
        question_statement: "",
        options: [{ text: "" }, { text: "" }],
        is_multiple_allow: true,
      },
    ]);
    setFormAction("ADD");
    setSelectedFeed({});
    setPollInfo({
      expiry_date: tomorrowDate,
      expiry_time: new Date(),
      is_multiple_allow: true,
    });
    setSurveyInfo({
      expiry_date: tomorrowDate,
      expiry_time: new Date(),
    });
    setIsLoading(false);
    setOpenCreateModal(false);
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (
      !inputs.VideoUrl &&
      !inputs.description &&
      !inputs.LiveUrl &&
      previews.length < 1 &&
      !oldThumbnail &&
      !thumbnail &&
      feedType !== "survey"
    ) {
      enqueueSnackbar("Please add data to be posted", { variant: "error" });
      return;
    }
    if (feedType === "poll") {
      if (!areOptionsUnique(pollOptions)) {
        enqueueSnackbar("All poll option must be unique", { variant: "error" });
        return;
      }
    }
    if (feedType === "survey") {
      if (!areQuestionsOptionsUnique(surveyQuestions)) {
        enqueueSnackbar("All options in each question must be unique", {
          variant: "error",
        });
        return;
      }
    }

    let expiry_date = new Date();
    let expiry_time = new Date();

    if (feedType === "poll") {
      expiry_date = moment(pollInfo.expiry_date).format("YYYY-MM-DD");
      expiry_time = moment(pollInfo.expiry_time).format("HH:mm");
    } else if (feedType === "survey") {
      expiry_date = moment(surveyInfo.expiry_date).format("YYYY-MM-DD");
      expiry_time = moment(surveyInfo.expiry_time).format("HH:mm");
    }

    const givenTime = new Date(expiry_date + " " + expiry_time);
    const timeNow = new Date(
      current_time_with_timezone(userInfo.time_zone, "YYYY-MM-DD hh:mm a")
    );
    if (givenTime <= timeNow) {
      enqueueSnackbar(
        "Past time selection is not allowed. Please choose a future time.",
        { variant: "error" }
      );
      return;
    }

    setIsLoading(true);
    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image;
      }
    });

    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();
      formData.append("feed_appear_by", feedAppearBy);

      formData.append("feed_type", feedType);
      formData.append("video_url", inputs.VideoUrl);
      formData.append("description", inputs.description);
      formData.append("embed_code", inputs.LiveUrl);
      if (list_type === "the_cosmos") {
        formData.append("created_for_level_or_type", [userLevel]);
      }
      // else if (list_type !== "event" && list_type !== "program") {
      //   if (
      //     formAction === "ADD" &&
      //     isAccess.multiple_levels_in_source_all_source_scadule_feeds
      //   ) {
      //     formData.append("badge_level_ids", JSON.stringify(PostLevel));
      //   } else if (formAction === "ADD") {
      //     formData.append("badge_level_ids", JSON.stringify([userLevel]));
      //   } else {
      //     formData.append("badge_level_ids", userLevel);
      //   }
      // }

      if (eventInfo.is_event_info) {
        formData.append("event_info", JSON.stringify(eventInfo));
      }

      if (feedType === "image") {
        formData.append("feed_images", JSON.stringify(img_results));
      } else if (feedType === "poll") {
        let poll_info = { ...pollInfo, expiry_date, expiry_time };
        poll_info.options = pollOptions;
        formData.append("poll_info", JSON.stringify(poll_info));
      } else if (feedType === "survey") {
        let survey_info = { ...surveyInfo, expiry_date, expiry_time };
        survey_info.questions = surveyQuestions;
        formData.append("survey_info", JSON.stringify(survey_info));
      }
      if (is_scheduled) {
        let start_date = moment(inputs.start_date).format("YYYY-MM-DD");
        let start_time = moment(inputs.start_time).format("HH:mm");
        formData.append("schedule_date_time", start_date + " " + start_time);
      }
      if (formAction === "ADD") {
        formData.append("is_publish", inputs.is_publish);
        formData.append("feed_created_for", feed_created_for);
        if (list_type === "event") {
          formData.append("event_id", event_id);
        } else if (list_type === "program") {
          formData.append("program_id", event_id);
        } else if (list_type === "mission") {
          formData.append("mission_id", event_id);
        }
      }

      formData.append("mentioned_users", JSON.stringify(mentionedUsers));
      if (projectMode === "DEV") {
        //for zoom start
        if (feedType === "live") {
          if (!thumbnail && !oldThumbnail) {
            enqueueSnackbar("Please upload live stream image", {
              variant: "error",
            });
            setIsLoading(false);
            return;
          }
          formData.append("image", thumbnail);
        }
      }
      const result =
        formAction === "ADD"
          ? await add_new_feed_with_poll(formData)
          : await update_feed_with_poll(formData, inputs._id);
      if (result.code === 200) {
        if (result.action_response?.notification_users?.length > 0) {
          const socketData = {
            action: "feed_mentioned",
            feed_id: result.action_response?.feed._id,
            token: localStorage.getItem("token"),
            creator_id: userInfo._id,
            action_by: userInfo._id,
            action_response: result.action_response,
          };
          socket.emit("mention_user_event_listner", socketData);
        }

        if (formAction === "ADD" && projectMode === "DEV") {
          //for zoom start
          if (feedType === "live") {
            navigate(
              `/live-stream/${result.feed._id}/${result.feed.zoom_token}`
            );
          }
          //for zoom end
        }

        if (formAction === "ADD") {
          if (feedAppearBy === "win") {
            setWinFeedsList((old) => {
              const newArray = [...old];
              newArray.splice(1, 0, result.feed);
              return newArray;
            });
          } else {
            getFeedsListing();
          }
        } else {
          handleSuccessUpdate(selectedFeed);
        }
        handleClose();
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handle_date = (type, feed_type) => {
    let format = "YYYY-MM-DD";
    if (type === "time") {
      format = "YYYY-MM-DD hh:mm a";
    }
    return TIME_ZONE_CONVERSION(
      feed_type == "poll"
        ? selectedFeed.poll_info.expiry_date_time
        : selectedFeed.survey_info.expiry_date_time,
      format,
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const handleChangeLevel = (event) => {
    let value = event.target.value;
    setUserLevel(value);
  };

  const handleCancelUpdate = (e) => {
    e.preventDefault();
    handleClose();
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD HH:mm",
      adminTimeZone,
      adminTimeZone
    );
  };

  useEffect(() => {
    if (selectedFeed?._id) {
      setFormAction("EDIT");
      setFeedAppearBy?.(selectedFeed.feed_appear_by);
      setInputs({
        ...selectedFeed,
        VideoUrl: selectedFeed.video_url,
        LiveUrl: selectedFeed.embed_code,
        start_date: dayjs(get_start_end_time(selectedFeed.schedule_date_time))
          .$d,
        start_time: dayjs(get_start_end_time(selectedFeed.schedule_date_time))
          .$d,
      });
      setFeedType(selectedFeed.feed_type);
      if (
        selectedFeed.feed_type === "image" &&
        selectedFeed.feed_images &&
        selectedFeed.feed_images.length > 0
      ) {
        setPreviews(selectedFeed.feed_images);
      }

      if (selectedFeed.feed_type == "live") {
        setOldThumbnail(selectedFeed.image?.thumbnail_1);
      }

      if (selectedFeed.event_info?.is_event_info) {
        setEventInfo(selectedFeed.event_info);
      }
      if (list_type == "the_cosmos") {
        setUserLevel(selectedFeed.created_for_level_or_type);
      } else {
        setUserLevel(selectedFeed.badge_level_info?._id);
      }
      if (selectedFeed.feed_type === "poll") {
        setPollInfo({
          ...selectedFeed.poll_info,
          expiry_date: handle_date("date", "poll"),
          expiry_time: new Date(handle_date("time", "poll")),
        });
        setPollOptions(selectedFeed.poll_info?.options);
      } else if (selectedFeed.feed_type === "survey") {
        setSurveyInfo({
          ...selectedFeed.survey_info,
          expiry_date: handle_date("date"),
          expiry_time: new Date(handle_date("time")),
        });
        setSurveyQuestions(selectedFeed.survey_info?.questions);
      }
    }
  }, [openCreateModal]);

  useEffect(() => {
    if (!selectedFeed?._id) {
      let val = userType;
      if (list_type == "the_cosmos") {
        if (!isAccess.choose_level_in_cosmos_feeds) {
          val = userInfo.team_type;
        } else if (isAccess.default_filter == "all") {
          val = userInfo.team_type;
        } else {
          val = isAccess.default_filter;
        }
      }
      setUserLevel(val);
    }
  }, [userType, openCreateModal]);

  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <form className="create-post-title-form" onSubmit={handleCreatePost}>
          <Box sx={style} className="modal-theme row">
            <div className="text-center modalIcon clo-12 create-post-title-box">
              <h3>{`${formAction == "ADD" ? "Create" : "Update"} Post`}</h3>
              <hr />
              <span
                onClick={() => {
                  handleClose();
                }}
              >
                x
              </span>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="post-creator align-items-center">
                  <Avatar
                    src={s3baseUrl + userInfo?.image?.thumbnail_1}
                    alt="photoURL"
                  >
                    {string_avatar(
                      userInfo?.last_name
                        ? userInfo?.first_name + " " + userInfo.last_name
                        : userInfo?.first_name
                    )}
                  </Avatar>
                  <div className="creator-name ps-1">
                    <h3 className="mb-0">
                      {userInfo?.first_name + " " + userInfo?.last_name}
                    </h3>
                    <div className="d-flex flex-wrap mt-1">
                      {list_type == "the_cosmos" && (
                        <>
                          {isAccess.choose_level_in_cosmos_feeds &&
                            isAccess.cosmos_feed_filters.length > 0 && (
                              <>
                                <div className="me-2">
                                  <select
                                    name="feedAppearBy"
                                    onChange={handleChangeLevel}
                                  >
                                    {isAccess.cosmos_feed_filters.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            {item != "all" && (
                                              <option
                                                value={item}
                                                selected={
                                                  userLevel == item
                                                    ? "selected"
                                                    : ""
                                                }
                                                className="text-capitalize"
                                              >
                                                {show_proper_words(
                                                  item == "marketing"
                                                    ? "marketing team"
                                                    : item
                                                )}
                                              </option>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </>
                            )}
                        </>
                      )}
                      {formAction == "ADD" &&
                        list_type !== "program" &&
                        list_type !== "mission" && (
                          <div className="me-2">
                            <select
                              name="is_publish"
                              onChange={handleChange}
                              disabled
                            >
                              <option
                                value={true}
                                selected={
                                  inputs.is_publish == true ? "selected" : ""
                                }
                              >
                                Publish
                              </option>
                              <option
                                value={false}
                                selected={
                                  inputs.is_publish == false ? "selected" : ""
                                }
                              >
                                Schedule
                              </option>
                            </select>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-2 position-relative">
              <MentionEditor
                setInputs={setInputs}
                inputs={inputs}
                name="description"
                setMentionedUsers={setMentionedUsers}
                mentionedUsers={
                  selectedFeed.mentioned_users?.length > 0
                    ? selectedFeed.mentioned_users
                    : mentionedUsers
                }
                feedLevels={
                  formAction === "ADD" &&
                  list_type !== "the_cosmos" &&
                  isAccess.multiple_levels_in_source_all_source_scadule_feeds
                    ? PostLevel
                    : list_type === "the_cosmos" && formAction === "ADD"
                    ? [userLevel]
                    : [userLevel]
                }
                placeholder={`What's on your mind, ${userInfo?.first_name}?`}
                value={inputs.description}
                list_type={list_type}
                event_id={event_id}
                addPosition={{ top: 15, left: 15 }}
              />
            </div>
            {is_scheduled && (
              <ScheduleTime inputs={inputs} setInputs={setInputs} />
            )}

            {feedType === "poll" ? (
              <>
                {isAccess.enable_poll_feed && (
                  <CreatePoll
                    pollInfo={pollInfo}
                    setPollInfo={setPollInfo}
                    pollOptions={pollOptions}
                    setPollOptions={setPollOptions}
                  />
                )}
              </>
            ) : feedType === "survey" ? (
              <>
                {isAccess.enable_survey_feed && (
                  <CreateSurvey
                    surveyInfo={surveyInfo}
                    setSurveyInfo={setSurveyInfo}
                    surveyQuestions={surveyQuestions}
                    setSurveyQuestions={setSurveyQuestions}
                  />
                )}
              </>
            ) : (
              feedType === "video" && (
                <div className="col-12 mt-2 video-url-field search-input">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Video URL"
                    name="VideoUrl"
                    value={inputs.VideoUrl}
                    onChange={handleChange}
                  />
                  <span
                    className="video-url-cross"
                    onClick={() => {
                      setFeedType("general");
                    }}
                  >
                    x
                  </span>
                </div>
              )
            )}
            {feedType === "live" &&
              (projectMode == "DEV" ? (
                //for zoom start
                <div className="col-md-12 mt-2 d-flex w-100">
                  {thumbnail ? (
                    <span className="preview mt-2">
                      <span onClick={handleRemove}>x</span>
                      <img src={URL.createObjectURL(thumbnail)} />
                    </span>
                  ) : (
                    oldThumbnail && (
                      <span className="preview mt-2">
                        <span onClick={handleRemove}>x</span>
                        <img src={s3baseUrl + oldThumbnail} />
                      </span>
                    )
                  )}
                  {thumbnail == "" && !oldThumbnail && (
                    <span className="upload-button mt-2 w-100">
                      <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        onChange={handleUploadThumbnail}
                      />
                      <label htmlFor="icon-button-file">
                        <span
                          onClick={() => {
                            setFeedType("general");
                          }}
                        >
                          x
                        </span>
                        <p className="mt-3">
                          Add Live Stream Photo <br />
                          <CloudUploadIcon />
                        </p>
                      </label>
                    </span>
                  )}
                  {/* //for zoom end */}
                </div>
              ) : (
                //for live textare start
                <div className="col-12 mt-2 video-url-field search-input">
                  <textarea
                    rows="6"
                    className="form-control"
                    placeholder="Livestream Embed Code"
                    name="LiveUrl"
                    value={inputs.LiveUrl}
                    onChange={handleChange}
                  >
                    {inputs.LiveUrl}
                  </textarea>
                  <span
                    className="video-url-cross"
                    onClick={() => {
                      setFeedType("general");
                    }}
                  >
                    x
                  </span>
                </div>

                //for live textare end
              ))}

            {feedType === "embed_code" && (
              <div className="col-12 mt-2 video-url-field search-input">
                <textarea
                  rows="6"
                  className="form-control"
                  placeholder="Embed Code"
                  name="LiveUrl"
                  value={inputs.LiveUrl}
                  onChange={handleChange}
                >
                  {inputs.LiveUrl}
                </textarea>
                <span
                  className="video-url-cross"
                  onClick={() => {
                    setFeedType("general");
                  }}
                >
                  x
                </span>
              </div>
            )}

            {feedType === "image" && (
              <div className="col-md-12 mt-2">
                <div className="row">
                  {previews.length > 0 &&
                    previews.map((preview) => {
                      return (
                        <span className="col-3 mb-2 preview create-post-images">
                          <span
                            onClick={() => {
                              handleRemoveImage(preview);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              preview.type == "file"
                                ? URL.createObjectURL(preview.path)
                                : s3baseUrl + preview.thumbnail_1
                            }
                          />
                        </span>
                      );
                    })}
                </div>
                {/* {previews.length < 1 && ( */}

                <div className="row">
                  <div className={`col-12`}>
                    <span className="upload-button mt-2 w-100">
                      <input
                        multiple
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                      />
                      <label htmlFor="icon-button-file">
                        <span
                          onClick={() => {
                            setFeedType("general");
                          }}
                        >
                          x
                        </span>
                        <p className="mt-3">
                          Add Photo <br />
                          <CloudUploadIcon />
                        </p>
                      </label>
                    </span>
                  </div>
                </div>

                {/* )} */}
              </div>
            )}

            {eventInfo.is_event_info && (
              <AddEventInfo setEventInfo={setEventInfo} eventInfo={eventInfo} />
            )}

            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div className="video-image-selection ps-0">
                  <Tooltip title="Upload Photo">
                    <div
                      onClick={() => {
                        setFeedType("image");
                      }}
                      className={`selection-box ${
                        feedType == "image" ? "selected" : ""
                      }`}
                    >
                      <PhotoCameraIcon />
                    </div>
                  </Tooltip>
                  <Tooltip title="Video Link">
                    <div
                      onClick={() => {
                        setFeedType("video");
                      }}
                      className={`selection-box ${
                        feedType == "video" ? "selected" : ""
                      }`}
                    >
                      <VideocamIcon />
                    </div>
                  </Tooltip>
                  {/* {userInfo.is_super_delegate && (
                    <div
                      onClick={() => {
                        if (userInfo.is_executive) {
                          setFeedType("live");
                        }
                      }}
                      className={`selection-box ${
                        feedType == "live" ? "selected" : ""
                      }  ${userInfo.is_executive ? "" : "force-disabled"}`}
                    >
                      <LiveTvIcon />
                    </div>
                  )} */}
                  <Tooltip title="Embed Code">
                    <div
                      onClick={() => {
                        setFeedType("embed_code");
                      }}
                      className={`selection-box ${
                        feedType == "embed_code" ? "selected" : ""
                      }`}
                    >
                      <CodeIcon />
                    </div>
                  </Tooltip>
                  {isAccess.enable_poll_feed && (
                    <Tooltip title="Poll">
                      <div
                        onClick={() => {
                          setFeedType("poll");
                        }}
                        className={`selection-box ${
                          feedType == "poll" ? "selected" : ""
                        }`}
                      >
                        <PollIcon />
                      </div>
                    </Tooltip>
                  )}
                  {isAccess.enable_survey_feed && (
                    <Tooltip title="Survey">
                      <div
                        onClick={() => {
                          setFeedType("survey");
                        }}
                        className={`selection-box ${
                          feedType == "survey" ? "selected" : ""
                        }`}
                      >
                        <AssignmentIcon />
                      </div>
                    </Tooltip>
                  )}
                  {isAccess.event_info_in_source_all_source_scadule_feeds &&
                    (list_type == "scheduled" ||
                      list_type == "the_source" ||
                      (list_type == "event" && is_scheduled) ||
                      list_type == "all_source") && (
                      <Tooltip
                        title={`${
                          eventInfo.is_event_info ? "Remove" : "Add"
                        } Event Info`}
                      >
                        <div
                          onClick={() => {
                            setEventInfo((old) => {
                              return {
                                ...old,
                                is_event_info: !old.is_event_info,
                              };
                            });
                          }}
                          className={`selection-box ${
                            eventInfo.is_event_info ? "selected" : ""
                          }`}
                        >
                          <EventAvailableIcon />
                        </div>
                      </Tooltip>
                    )}
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              {formAction === "ADD" && (
                <button
                  className="small-contained-button post-submit-btn"
                  disabled={isLoading}
                >
                  {isLoading ? "Posting..." : "Post"}
                </button>
              )}
              {formAction === "EDIT" && (
                <div className="d-flex justify-content-end">
                  <button
                    className="me-2 small-contained-button post-submit-btn"
                    onClick={handleCancelUpdate}
                  >
                    Cancel
                  </button>
                  <button
                    className="small-contained-button post-submit-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
