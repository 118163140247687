import React, { useState } from "react";

const GeneralQuestionStarRating = ({
  question,
  handleAnswer,
  find_answer,
  disabled,
}) => {
  const [hoverFill, setHoverFill] = useState(null);

  const handleRating = (e, value) => {
    if (!disabled) {
      handleAnswer?.(question._id, value);
    }
  };

  console.log(disabled, "disableddisableddisableddisabled");

  return (
    <div className="star">
      {[...Array(question.scaling_max)].map((_, index) => {
        const ratingValue = index + 1;

        return (
          <button
            key={index}
            onMouseEnter={() =>
              !disabled ? setHoverFill(ratingValue) : () => {}
            }
            onMouseLeave={() => (!disabled ? setHoverFill(null) : () => {})}
            onClick={(w) => handleRating(w, ratingValue)}
            style={{
              background:
                ratingValue <= (hoverFill || find_answer?.answer_statement)
                  ? "#B59D4E"
                  : "transparent",
              textAlign: "center",
            }}
            className="FaStar-button mb-2"
          >
            <p
              className="FaStar mb-0"
              style={{
                color:
                  ratingValue <= (hoverFill || find_answer?.answer_statement)
                    ? "#ffffff"
                    : "#B59D4E",
              }}
            >
              {ratingValue}
            </p>
          </button>
        );
      })}
    </div>
  );
};

export default GeneralQuestionStarRating;
