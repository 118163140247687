import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function ActiveLastBreadcrumb({ breadCrumbMenu }) {
  const navigate = useNavigate();

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbMenu.map((item, index) => {
          return (
            <Link
              underline="hover"
              color={item.active == true ? "#e4c073" : "white"}
              onClick={() => navigate(item?.navigation)}
            >
              <span style={{ cursor: "pointer" }}>{item?.title}</span>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
