import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Container,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
const ReportUsers = () => {
  const classes = useStyles();

  const [Activities, setActivitiesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [cout, setCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleToggle = (item, index) => {
    setActivitiesArray((old) =>
      old.map((item, i) => {
        item.isExpand = i === index ? !item.isExpand : false;
        return item;
      })
    );
  };

  function makeArrayUnique(array) {
    const uniqueMap = new Map();
    array.forEach((obj) => {
      uniqueMap.set(obj._id, obj);
    });
    return Array.from(uniqueMap.values());
  }

  // if (isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <div className="row mt-3">
      {makeArrayUnique(Activities).map((item, index) => {
        return (
          <Container className="mt-2">
            <Accordion expanded={Boolean(item.isExpand)}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    className="icon-color"
                    onClick={() => {
                      handleToggle(item, index);
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="svg-color summary"
              >
                <div
                  className="delegate-info"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <div className="delegate-info">
                    <Avatar
                      className="avatar-adjust"
                      sx={{ width: 30, height: 30 }}
                      alt={item.first_name}
                      src={s3baseUrl + item?.image?.thumbnail_1}
                    />
                    <span style={{ color: "white" }}>
                      {item.first_name + " " + item.last_name + " "}
                      <span className="text-muted">
                        {"(" + item.email + ")"}
                      </span>{" "}
                    </span>
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails
                style={{
                  backgroundColor: "#121826",
                  border: "2px solid #1D2537",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                TLAHA
              </AccordionDetails>
            </Accordion>
          </Container>
        );
      })}
    </div>
  );
};

export default ReportUsers;
