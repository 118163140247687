import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";

const GroupedChart = ({ graphStructure }) => {
  const removeRepetitions = (data) => {
    const uniqueData = [];
    const questionIds = new Set();

    data.forEach((item) => {
      if (!questionIds.has(item.question_id)) {
        questionIds.add(item.question_id);
        uniqueData.push(item);
      }
    });

    return uniqueData;
  };

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "sales",
        data: [
          {
            x: "",
            y: 0,
            question: "",
            color: "#FF5733",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "category",
        group: {
          groups: [],
        },
        labels: {
          style: {
            colors: "white",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white",
          },
        },
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "center",
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return `<div class="arrow_box" style="z-index: 9999999;">
                   <span class="question-text">Question: </span><span>${data.question}</span><br/>
                   <span class="question-text">Answer: </span><span>${data.y}</span>
                  </div>`;
        },
      },
      legend: {
        show: false, // Hide legends
      },
    },
  });

  useEffect(() => {
    if (graphStructure && graphStructure.length > 0) {
      const groupDataByDate = (data) => {
        const groupedData = data.reduce((acc, item) => {
          const { date } = item;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});

        return Object.values(groupedData);
      };

      const groupedData = groupDataByDate(graphStructure);
      const sortDataByDate = (data) => {
        return data.sort((a, b) => new Date(a.date) - new Date(b.date));
      };

      const sortedData = graphStructure;
      // Prepare series data and map each bar to its color
      const seriesData = sortedData.map((item) => {
        return {
          x: " ",
          y: item.answer_statement,
          question: item.question_statement,
          color: item.scaling_color, // Assign color directly to each data point
        };
      });

      setChartData((prevState) => ({
        ...prevState,
        series: [
          {
            name: "sales",
            data: seriesData,
          },
        ],
        options: {
          ...prevState.options,
          plotOptions: {
            ...prevState.options.plotOptions,
            bar: {
              ...prevState.options.plotOptions.bar,
              distributed: true,
            },
          },
          xaxis: {
            ...prevState.options.xaxis,
            group: {
              ...prevState.options.xaxis.group,
              style: {
                fontSize: "10px",
                fontWeight: 700,
                colors: groupedData.map((item) => "#fff"),
              },
              groups: groupedData.map((item) => {
                return { title: item[0].date, cols: item.length };
              }),
            },
          },
          colors: seriesData.map((data) => data.color),
        },
      }));
    }
  }, [graphStructure]);

  return (
    <div>
      {/* Scrollable container */}
      <div
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <div
          id="chart"
          style={{
            position: "relative",
            zIndex: 999,
            minWidth: "1000px", // Ensures a wide chart for scrolling
          }}
        >
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={280}
          />
        </div>
      </div>

      <div id="html-dist"></div>

      <div
        className="d-flex justify-content-start mb-3 flex-wrap for-mobile-indication mx-2 mt-3"
        style={{ alignItems: "end" }}
      >
        {removeRepetitions(graphStructure) &&
          removeRepetitions(graphStructure).length > 0 &&
          removeRepetitions(graphStructure).map((option) => (
            <span className="" key={option.question_id}>
              <div
                className="d-flex align-content-center me-3"
                style={{ alignItems: "center", opacity: 1, cursor: "pointer" }}
              >
                <span
                  className="box-1 me-1"
                  style={{
                    border: `1px solid ${
                      option.scaling_color ? option.scaling_color : "#000"
                    }`,
                    background: option.scaling_color
                      ? option.scaling_color
                      : "#000",
                  }}
                />
                <span className="" style={{ fontSize: "12px" }}>
                  <div
                    className="color-white"
                    dangerouslySetInnerHTML={{
                      __html: option.question_statement,
                    }}
                  ></div>
                </span>
              </div>
            </span>
          ))}
      </div>
    </div>
  );
};

export default GroupedChart;
