import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { VaultListing } from "src/DAL/Vault/Vault";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { ProgramListing } from "src/DAL/Program/Programs";
import { AddRecording } from "src/DAL/Recording/Recordings";
import { get_root_value } from "src/utils/domUtils";
import { useEffect } from "react";
import {
  GetProfileApi,
  update_zoom_setting_api,
} from "src/DAL/Profile/Profile";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  button: {
    backgroundColor: get_root_value("--button-background-color"),
    color: get_root_value("--portal-theme-primary"),
    "&:hover": {
      backgroundColor: get_root_value("--button-background-color"),
      color: get_root_value("--portal-theme-primary"),
    },
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function ZoomSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContentSetting();
  const [file, setProfileImage] = useState();
  const [audioFile, setAudio] = useState();
  const [programList, setProgramList] = useState([]);
  const [vaultList, setVaultList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [programName, setProgramName] = useState("");
  const [vaultName, setVaultName] = useState("");

  const [inputs, setInputs] = useState({
    zoom_api_key: "",
    zoom_api_secret: "",
    zoom_account_id: "",
  });
  const consultantProfile = async () => {
    const result = await GetProfileApi(userInfo._id);
    if (result.code === 200) {
      setInputs((inputs) => ({
        ...inputs,
        ["zoom_api_key"]: result.consultant.zoom_api_key,
        ["zoom_api_secret"]: result.consultant.zoom_api_secret,
        ["zoom_account_id"]: result.consultant.zoom_account_id,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      zoom_api_key: inputs.zoom_api_key,
      zoom_api_secret: inputs.zoom_api_secret,
      zoom_account_id: inputs.zoom_account_id,
    };

    const result = await update_zoom_setting_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    consultantProfile();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      {/* <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <h2>Zoom Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zoom Api Public Key"
            variant="outlined"
            fullWidth
            name="zoom_api_key"
            value={inputs.zoom_api_key}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zoom Api Secret Key"
            variant="outlined"
            fullWidth
            name="zoom_api_secret"
            value={inputs.zoom_api_secret}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zoom Account Id"
            variant="outlined"
            fullWidth
            name="zoom_account_id"
            value={inputs.zoom_account_id}
            onChange={handleChange}
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
