import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import React from "react";
import GeneralQuestionStarRating from "../GeneralComponents/GeneralQuestions/GeneralQuestionStarRating";

export default function ScheduleQuestionsCard(props) {
  const { heading, hide_Top_section, schedule_questions } = props;

  const handleChangeAnswer = () => {};

  return (
    <div className="col-12 position-relative">
      {/* <form
              className={` ${
                hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
              } allowed-actions-box pb-4`}
            > */}
      <div
        className={` ${
          hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
        } allowed-actions-box pb-4`}
      >
        {!hide_Top_section && (
          <h2 style={{ marginBottom: "14px", fontSize: "18px" }}>{heading}</h2>
        )}

        {schedule_questions.map((item, index) => {
          let { is_required } = item;
          return (
            <>
              <div className="action-main-container">
                <div className={`mb-2 action-checkbox-box`}>
                  <div className="checkbox-label">
                    <div
                      className="question-container"
                      dangerouslySetInnerHTML={{
                        __html: item.question_statement,
                      }}
                    ></div>
                  </div>
                  <span className="required-asterisk ms-1">
                    {is_required && "*"}
                  </span>
                </div>
                {item.question_type == "checkbox" ? (
                  <FormControl>
                    <FormGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={item?.answer}
                    >
                      {item?.options.map((option, index) => {
                        return (
                          <div key={option.id}>
                            <FormControlLabel
                              value={option}
                              id={`capsule${index}`}
                              className={`answer`}
                              control={<Checkbox />}
                              label={option}
                            />
                          </div>
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                ) : item.question_type == "mcq" ? (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={item?.answer_statement}
                    >
                      {item?.options.map((option, index) => (
                        <div key={option.id}>
                          <FormControlLabel
                            value={option}
                            id={`capsule${index}`}
                            className={`answer`}
                            control={<Radio />}
                            label={option}
                          />
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : item.question_type == "scaling" ? (
                  <FormControl>
                    <ul className="list-unstyled pt-1 mb-0">
                      <li className="row">
                        <div className={`col-12`}>
                          <GeneralQuestionStarRating
                            question={item}
                            handleAnswer={handleChangeAnswer}
                            disabled={true}
                            disableTooltip={true}
                          />
                        </div>
                      </li>
                    </ul>
                  </FormControl>
                ) : (
                  <div className="action-checkbox-box-description">
                    <TextareaAutosize
                      aria-label="empty textarea"
                      className={`text-color textarea-autosize`}
                      value={""}
                      name="answer_statement"
                      placeholder={item?.question_placeholder}
                      sx={{ color: "white" }}
                      minRows={3}
                      style={{
                        width: "100%",
                        borderRadius: "11px",
                        background: "#222b3e",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        color: "white",
                        fontSize: "15px",
                        border: "2px solid #222b3e",
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
