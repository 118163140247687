import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";

export default function ComparisonChart({ onScreenQuestion }) {
  console.log(onScreenQuestion, "onScreenQuestiononScreenQuestion");
  const removeRepetitions = (data) => {
    const uniqueData = [];
    const questionIds = new Set();

    data.forEach((item) => {
      if (!questionIds.has(item.question_id)) {
        questionIds.add(item.question_id);
        uniqueData.push(item);
      }
    });

    return uniqueData;
  };

  function generateRandomColor() {
    // Generate a random color in hexadecimal format
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`;
  }

  function transformQuestions(questions, dates) {
    const result = [];

    // Group questions by their statement and value
    const groupedQuestions = questions.reduce((acc, curr) => {
      const {
        question_statement,
        question_id,
        answer_statement,
        date,
        scaling_color,
      } = curr;

      if (!acc[question_statement]) {
        acc[question_statement] = {
          name: question_statement,
          value: question_id,
          data: {},
          color: scaling_color,
          group: "apexcharts-axis-0",
        };
      }

      // Assign the answer_statement to the corresponding date
      acc[question_statement].data[date] = answer_statement;
      return acc;
    }, {});

    // Create the final array with the correct data order
    for (const key in groupedQuestions) {
      const item = groupedQuestions[key];
      const dataArray = dates.map((date) => item.data[date] || 0); // Fill missing dates with 0
      result.push({ ...item, data: dataArray });
    }

    return result;
  }

  const uniqueDates = [...new Set(onScreenQuestion?.map((item) => item.date))];

  // Input arrays

  // Transform the questions
  const transformedData = transformQuestions(onScreenQuestion, uniqueDates);
  let lineChartWin = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        min: 0, // Set minimum value
        max: 10, // Set maximum value
      },
      xaxis: {
        categories: uniqueDates,
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisTicks: {
          show: false, // This hides the default x-axis ticks
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val === 0 ? "" : val;
        },
      },
      legend: {
        show: true,
        labels: {
          colors: "#FFFFFF", // Sets legend text color to white
        },
      },
    },
    series: transformedData,
  };

  return (
    <>
      <div className="card">
        <h4
          className="mt-3 mb-0 ms-3"
          style={{ color: "rgb(255, 216, 137)", fontSize: 18 }}
        >
          Comparison Chart
        </h4>
        <Chart
          options={lineChartWin.options}
          series={lineChartWin.series}
          type="line"
          height={300}
        />
        <div
          className="d-flex justify-content-start mb-3 flex-wrap for-mobile-indication mx-2 ms-4 mb-3"
          style={{ alignItems: "end" }}
        >
          {removeRepetitions(onScreenQuestion) &&
            removeRepetitions(onScreenQuestion).length > 0 &&
            removeRepetitions(onScreenQuestion).map((option) => (
              <span className="" key={option.question_id}>
                <div
                  className="d-flex align-content-center me-3"
                  style={{
                    alignItems: "center",
                    opacity: 1,
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="box-1 me-1"
                    style={{
                      border: `1px solid ${
                        option.scaling_color ? option.scaling_color : "#000"
                      }`,
                      background: option.scaling_color
                        ? option.scaling_color
                        : "#000",
                    }}
                  />
                  <span className="" style={{ fontSize: "12px" }}>
                    <div
                      className="color-white"
                      dangerouslySetInnerHTML={{
                        __html: option.question_statement,
                      }}
                    ></div>
                  </span>
                </div>
              </span>
            ))}
        </div>
      </div>
    </>
  );
}
