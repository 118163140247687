import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import { pods_list_api_v1 } from "src/DAL/delegatePods/delegatePods";
import PodsFilter from "./PodsFilter";
import { s3baseUrl } from "src/config/config";
import { show_proper_words } from "src/utils/constants";
import { DeletePod } from "src/DAL/Pods/Pods";
import RecordNotFound from "src/components/RecordNotFound";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import UserListToolbarsForSearch from "src/components/_dashboard/user/UserListToolbarsForSearch";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarPodsListBookcall() {
  const navigate = useNavigate();
  const observer = useRef();
  const classes = useStyles();
  const [podsList, setPodsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");

  const EMPTY_FILTER = {
    badge_levels: [],
    search_text: "",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const get_pods_List = async (filter_data, path) => {
    if (!path) setIsLoading(true);

    let postData = { ...filter_data };
    const result = await pods_list_api_v1("booking", postData, path);
    if (result.code === 200) {
      const data = result.room.map((room) => {
        let recurring_type = "No";
        let data_time_start = "N/A";
        let by_action = "N/A";

        if (room.is_recurring) {
          recurring_type = `Yes (${show_proper_words(room.recurring_type)})`;
        }

        if (room.start_date_time) {
          data_time_start = moment(room.start_date_time).format(
            "DD-MM-YYYY hh:mm A"
          );
        }

        if (room.action_by_info) {
          by_action = room.action_by_info;
        }

        return {
          ...room,
          table_avatar: {
            src: s3baseUrl + room?.room_image?.thumbnail_1,
            alt: room.title,
          },
          recurring_type,
          data_time_start,
          by_action,
        };
      });
      if (path) {
        setPodsList((old) => [...old, ...data]);
      } else {
        setPodsList(data);
      }
      setLoadMorePath(result.load_more_url);

      let chipData = { ...filter_data };
      if (chipData.pod_type == "all") {
        delete chipData.pod_type;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/book-call-pods/edit-pod/${value.room_slug}`, {
      state: { editValues: value.object },
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/book-call-pods/pods-detail/${value.room_slug}`, {
      state: { ...value, pods_name: "Book Call Pods" },
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeletePod(deleteDoc.room_slug);
    if (result.code === 200) {
      get_pods_List(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    filterData.search_text = searchText;
    get_pods_List(filterData);
    localStorage.removeItem("pods_filter_data");
    localStorage.setItem("pods_filter_data", JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    get_pods_List(EMPTY_FILTER);
    setFilterDrawerState(false);
    localStorage.removeItem("pods_filter_data");
  };

  const handleDeleteChip = (data) => {
    data.search_text = searchText;
    get_pods_List(data);
    localStorage.removeItem("pods_filter_data", JSON.stringify(data));
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      get_pods_List(filterData, loadMorePath);
    }
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("pods_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    get_pods_List(filter_data);
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Book Call Pods</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
            </div>
          </div>
          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
          <div className="col-12 d-flex justify-content-end">
            <UserListToolbarsForSearch
              filterName={searchText}
              onFilterName={handleSearchText}
              handleSubmit={searchFunction}
            />
          </div>
        </div>
        <div className="container">
          <div className="row">
            {podsList.length == 0 ? (
              <RecordNotFound title="Pods" />
            ) : (
              podsList.map((value, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
                    <div className="card mt-4 pods-cards-shadow cursor h-100">
                      <img
                        src={s3baseUrl + value.room_image.thumbnail_2}
                        className="card-img-top pods-image"
                        alt="Pods"
                        onClick={() => handleViewDetail(value)}
                      />
                      <div className="card-body">
                        <div className="row">
                          <div onClick={() => handleViewDetail(value)}>
                            <h3 className="h2-heading pe-4">
                              {htmlDecode(value.title)}
                            </h3>
                          </div>
                          <div className="menu_pod_card_icon">
                            <CustomPopover menu={MENU_OPTIONS} data={value} />
                          </div>
                          <CustomConfirmation
                            open={openDelete}
                            setOpen={setOpenDelete}
                            title={"Are you sure you want to delete this pod?"}
                            handleAgree={handleDelete}
                          />
                        </div>
                        <p className="programme-card-desc mb-3">
                          {htmlDecode(value.short_description)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

            {loadMorePath && (
              <div className="col-12 text-center mt-4">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMore ? (
                    <CircularProgress color="primary" />
                  ) : (
                    "Load More"
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <PodsFilter
              filterData={filterData}
              handleChange={handleChange}
              setFilterData={setFilterData}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
              handleChangeOthers={handleChangeOthers}
            />
          }
        />
      </div>
    </>
  );
}
