import React from "react";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";

export default function ScheduleTime(props) {
  const { inputs, setInputs } = props;

  const handleChangeDates = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value.$d }));
  };

  return (
    <>
      <div className="col-12 col-sm-7">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Publish Date (Europe/Dublin)"
            name="start_date"
            inputFormat="DD-MM-YYYY"
            value={inputs.start_date}
            format="DD-MM-YYYY"
            className="mt-3"
            onChange={(e) => handleChangeDates("start_date", e)}
            renderInput={(params) => (
              <TextField {...params} required={true} size="small" />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="col-12 col-sm-5">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Publish Time"
            name="start_time"
            value={inputs.start_time}
            minutesStep={15}
            sx={{ color: "#fff" }}
            className="mt-3"
            onChange={(e) => handleChangeDates("start_time", e)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}
