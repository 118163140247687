import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import {
  _get_source_api_link_v1,
  get_delegate_missions_api,
} from "src/DAL/Missions/Missions";
import MissionCardsList from "src/components/MissionLevels/Missions/MissionCardsList";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionsList() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { level_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [missionList, setMissionList] = useState([]);
  const [questList, setQuestList] = useState([]);
  const [badgeLevel, setBadgeLevel] = useState({});

  const get_mission_levels = async () => {
    const result = await get_delegate_missions_api(level_id);
    if (result.code === 200) {
      setMissionList(result.missions);
      setQuestList(result.quests);
      setBadgeLevel(result.badge_level);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const copySourceAppUrl = async (row) => {
    let copy_text = row.delegate_code;
    if (!copy_text) {
      const PostData = {
        type: "mission",
        mission_id: row._id,
      };
      const result = await _get_source_api_link_v1(PostData);
      copy_text = result.url;
    }
    navigator.clipboard.writeText(copy_text);
    enqueueSnackbar("URL copied to clipboard", { variant: "success" });
  };

  let breadCrumbMenu = [
    {
      title: "Mission Levels",
      navigation: `/mission-levels`,
    },
    {
      title: badgeLevel?.tagline,
      active: true,
    },
  ];

  useEffect(() => {
    get_mission_levels();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mission-levels mb-3">
      <div className="mission-level-heading mb-3">
        <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
      </div>
      <div className="row">
        <div className="col-12">
          {badgeLevel.detailed_description && (
            <div
              className="mission_dashboard_description mt-1 mb-2"
              dangerouslySetInnerHTML={{
                __html: badgeLevel.detailed_description,
              }}
            ></div>
          )}

          {questList.length === 0 && missionList.length === 0 ? (
            <RecordNotFound title="No Data Found" />
          ) : (
            <>
              {questList.length > 0 && (
                <MissionCardsList
                  cardsList={questList}
                  type="quest"
                  copyUrl={copySourceAppUrl}
                />
              )}
              {missionList.length > 0 && (
                <MissionCardsList
                  cardsList={missionList}
                  type="mission"
                  copyUrl={copySourceAppUrl}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
