import React from "react";
import { s3baseUrl } from "src/config/config";
import { dd_date_format, labels, show_proper_words } from "src/utils/constants";
import moment from "moment";
import { MissionCoin } from "src/assets";
import { TextareaAutosize, Tooltip } from "@mui/material";
import ReactVideoPlayer from "../_dashboard/GeneralComponents/ReactVideoPlayer";
import ScheduleQuestionsCard from "./ScheduleQuestionsCard";

export default function DayVideoTab({
  schedule,
  hide_Top_section,
  missionContentSettings,
  playedContent,
}) {
  const replacedStringNote = (string) => {
    if (!string) return;
    return string
      .replace(/{started_at}/g, dd_date_format(schedule.started_at))
      .replace(/{end_date}/g, dd_date_format(schedule.end_date));
  };

  return (
    <div className={`row ${!hide_Top_section ? "mt-3" : ""}`}>
      {!hide_Top_section && (
        <>
          <div
            className={`col-12 ${
              schedule.detailed_description
                ? "col-lg-8"
                : schedule.short_description
                ? "col-lg-8"
                : ""
            } `}
          >
            {schedule.video_url ? (
              <ReactVideoPlayer
                url={schedule.video_url}
                playedContent={playedContent}
              />
            ) : (
              <img
                src={s3baseUrl + schedule.image.thumbnail_1}
                className="m-auto"
              />
            )}
          </div>

          <div className={`col-lg-4 mission-levels-overview-tab py-2 `}>
            <h2>
              <span style={{ fontSize: "14pt", color: "#ffd889" }}>
                Schedule Overview
              </span>
            </h2>{" "}
            {schedule.schedule_status == "locked" ||
            schedule.allowed_status == "missed" ? (
              <p>{schedule.short_description}</p>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: schedule.detailed_description,
                }}
              ></div>
            )}
            <div className="mt-3 mb-2 d-flex justify-content-between">
              <span className="indication-text">
                <img
                  className="me-1"
                  src={
                    "https://dd-feature-app-bucket.s3.amazonaws.com/upload/816051a3-2434-4897-a252-af2aead806ce.png"
                  }
                  alt=""
                  style={{ width: "15px" }}
                />{" "}
                {schedule.completed_at
                  ? moment(schedule.completed_at).format("DD MMM YYYY")
                  : schedule.total_number_of_days + " " + "day"}
              </span>
              <span className="indication-text">
                <img
                  className="me-1"
                  src={MissionCoin}
                  alt=""
                  style={{ width: "20px" }}
                />{" "}
                {schedule.reward_coins + " "}
                {schedule.reward_coins ? "Rewarded Coins" : "Achieveable Coins"}
              </span>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div className="w-100">
              {schedule?.audio_url && (
                <audio
                  src={s3baseUrl + schedule?.audio_url}
                  controls
                  className={`w-100`}
                  onPlay={schedule.video_url ? undefined : playedContent}
                />
              )}
            </div>
          </div>
        </>
      )}

      <>
        {schedule.general_allowed_actions?.length > 0 && (
          <div className="col-12 position-relative">
            <form
              className={` ${
                hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
              } allowed-actions-box pb-4`}
            >
              {!hide_Top_section && (
                <h2
                  style={{ marginBottom: "14px", fontSize: "18px" }}
                  className=""
                >
                  {schedule.content_settings?.action_statement_heading}
                </h2>
              )}

              {schedule.general_allowed_actions.map((item, index) => {
                let { _id, action_statement, is_required, is_checked } = item;

                let find_answer = null;
                if (schedule.completed_allowed_actions?.length > 0) {
                  find_answer = schedule.completed_allowed_actions?.find(
                    (i) => i._id == _id
                  );
                }

                return (
                  <>
                    <Tooltip
                      title={
                        schedule.allowed_status == "missed" ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: replacedStringNote(
                                missionContentSettings?.missed_schedule_description
                              ),
                            }}
                            className="schedule_completed_description"
                          ></div>
                        ) : (
                          ""
                        )
                      }
                    >
                      <div className="action-main-container mb-2">
                        <div className={`mt-2 action-checkbox-box`}>
                          <div
                            className={`action-checkbox ${
                              is_checked ? "checked" : ""
                            }`}
                          >
                            {is_checked && <i class="fa-solid fa-check"></i>}
                          </div>
                          <div className="checkbox-label">
                            {action_statement}
                          </div>
                          <span className="required-asterisk ms-1">
                            {is_required && "*"}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  </>
                );
              })}
            </form>
          </div>
        )}
        {schedule?.schedule_questions?.filter((q) => q.show_on === "on_screen")
          ?.length > 0 && (
          <ScheduleQuestionsCard
            schedule_questions={schedule?.schedule_questions?.filter(
              (q) => q.show_on === "on_screen"
            )}
            heading={
              schedule?.content_settings?.onscreen_question_title ||
              "Content Questions"
            }
          />
        )}
        {schedule?.schedule_questions?.filter(
          (q) => q.show_on === "after_action"
        )?.length > 0 && (
          <div className="mt-3">
            <ScheduleQuestionsCard
              schedule_questions={schedule?.schedule_questions?.filter(
                (q) => q.show_on === "after_action"
              )}
              heading={"Interactive Learning Experience"}
            />
          </div>
        )}

        {schedule.growth_tool_allowed_actions?.length > 0 && (
          <div className="row mt-2">
            {schedule.growth_tool_allowed_actions.map((item, parentIndex) => {
              let { _id, is_required } = item;

              let find_answer = null;
              if (schedule.completed_allowed_actions?.length > 0) {
                find_answer = schedule.completed_allowed_actions.find(
                  (i) => i._id == _id
                );
              }

              return (
                <div
                  className={`position-relative  ${
                    schedule.growth_tool_allowed_actions?.length <= 1
                      ? "col-lg-12"
                      : "col-lg-6"
                  } pe-0`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <form
                    className={` ${
                      hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
                    } allowed-actions-box pb-4 flex-grow-1`}
                  >
                    {!hide_Top_section && (
                      <h2
                        style={{ marginBottom: "14px", fontSize: "20px" }}
                        className=""
                      >
                        {item.tool == "gratitude_daily"
                          ? schedule?.content_settings
                              ?.gratitude_action_title ||
                            show_proper_words(item.tool)
                          : schedule?.content_settings
                              ?.dynamite_dairy_action_title ||
                            show_proper_words(item.tool)}
                      </h2>
                    )}

                    <>
                      <div className="action-main-container mb-2">
                        {item.growth_tool_data.statement_array.map(
                          (items, index) => {
                            const lable =
                              item.tool === "gratitude_daily"
                                ? labels.gratitudeLabels[index]
                                : labels.dailyDynamiteLabels[index];

                            return (
                              <>
                                <div className="action-checkbox-box-description">
                                  <span className="ms-2 custom-lable">
                                    {lable}
                                  </span>
                                  <span className="required-asterisk ms-1">
                                    {is_required && "*"}
                                  </span>
                                  <TextareaAutosize
                                    aria-label="empty textarea"
                                    className={`text-color textarea-autosize`}
                                    placeholder={index + 1}
                                    value=""
                                    sx={{ color: "white" }}
                                    minRows={1}
                                    style={{
                                      width: "100%",
                                      borderRadius: "11px",
                                      background: "#222b3e",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      paddingLeft: "15px",
                                      color: "white",
                                      fontSize: "15px",
                                      border: "2px solid #222b3e",
                                    }}
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </>
                  </form>
                </div>
              );
            })}
          </div>
        )}

        <>
          {schedule?.content_settings?.is_show_general_note && (
            <div className="action-checkbox-box-description  mt-3">
              <h4 className="ms-2" style={{ color: "#ffd889" }}>
                {schedule?.content_settings?.general_note_title || "Journal"}
              </h4>
              <TextareaAutosize
                aria-label="empty textarea"
                className={`text-color textarea-autosize`}
                name="statement"
                value={""}
                placeholder={labels.journal}
                sx={{ color: "white" }}
                minRows={4}
                style={{
                  width: "100%",
                  borderRadius: "11px",
                  background: "#222b3e",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "15px",
                  color: "white",
                  fontSize: "15px",
                  border: "2px solid #222b3e",
                }}
              />
            </div>
          )}
        </>
      </>
    </div>
  );
}
