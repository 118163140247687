import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { mission_schedule_detail_api } from "src/DAL/Missions/Missions";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import QuestDayTab from "src/components/MissionLevels/Quest/QuestDayTab";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestScheduleDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { level_id, schedule_id, mission_id } = useParams();
  const [missionDetail, setMissionDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [missionContentSettings, setMissionContentSettings] = useState(null);
  const [data, setData] = useState(null);
  const [showQuestionsSreen, setShowQuestionsSreen] = useState(0);

  const get_mission_detail = async () => {
    setIsLoading(true);
    const result = await mission_schedule_detail_api(schedule_id);
    if (result.code == 200) {
      setMissionContentSettings(result.mission.content_settings);
      setMissionDetail({ ...result.mission, user_data: result.user_data });
      let find_schedule = result.mission_schedule;
      const find_gratitude_growth_tool_object =
        find_schedule.completed_allowed_actions &&
        find_schedule.completed_allowed_actions.length > 0 &&
        find_schedule.completed_allowed_actions.find(
          (item) => item.type == "dynamite_dairy"
        );
      const find_gratitude_growth_tool = find_gratitude_growth_tool_object
        ? find_gratitude_growth_tool_object.gratitude_options
        : [];

      let data = {
        ...find_schedule,
        schedule_questions:
          find_schedule.schedule_questions &&
          find_schedule.schedule_questions.length > 0
            ? find_schedule.schedule_questions.map((item) => {
                return {
                  ...item,
                  answer_statement: item.answer_statement || "",
                };
              })
            : [],
        general_allowed_actions:
          find_schedule.general_allowed_actions &&
          find_schedule.general_allowed_actions.length > 0
            ? find_schedule.general_allowed_actions.map((item) => {
                return {
                  ...item,
                };
              })
            : [],
        growth_tool_allowed_actions:
          find_schedule.growth_tool_allowed_actions &&
          find_schedule.growth_tool_allowed_actions.length > 0
            ? find_schedule.growth_tool_allowed_actions
                .map((item) => {
                  return {
                    ...item,
                    growth_tool_data:
                      item.growth_tool_data &&
                      Object.keys(item.growth_tool_data).length > 0
                        ? item.growth_tool_data
                        : {
                            statement_array: [
                              {
                                option: "",
                              },
                              {
                                option: "",
                              },
                              {
                                option: "",
                              },
                            ],
                          },
                  };
                })
                .concat(
                  find_schedule.growth_tool_allowed_actions.map((item) => {
                    return {
                      ...item,
                      tool: "gratitude_daily",
                      growth_tool_data:
                        item.growth_tool_data &&
                        Object.keys(item.growth_tool_data).length > 0
                          ? {
                              ...item.growth_tool_data,
                              statement_array:
                                find_gratitude_growth_tool.length > 0
                                  ? find_gratitude_growth_tool
                                  : item.growth_tool_data.statement_array,
                            }
                          : {
                              statement_array: [
                                {
                                  option: "",
                                },
                                {
                                  option: "",
                                },
                                {
                                  option: "",
                                },
                              ],
                            },
                    };
                  })
                )
            : [],
      };

      setData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      handleBack();
    }
  };

  const handleBack = () => {
    if (showQuestionsSreen == 1) {
      setShowQuestionsSreen(0);
      localStorage.removeItem("showQuestionsSreen", 0);
      return;
    } else if (showQuestionsSreen == 2) {
      navigate(`/mission-levels/level-details/${schedule_id}`);
      localStorage.removeItem("showQuestionsSreen", 0);
    } else {
      navigate(-1);
      localStorage.removeItem("showQuestionsSreen", 0);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("showQuestionsSreen") == 1) {
      setShowQuestionsSreen(1);
    } else if (localStorage.getItem("showQuestionsSreen") == 2) {
      setShowQuestionsSreen(2);
    }
    get_mission_detail();
  }, []);

  let breadCrumbMenu = [
    {
      title: "Mission Levels",
      navigation: `/mission-levels`,
    },
    {
      title:
        missionDetail?.membership_level_info?.badge_level_info?.tagline ||
        "Levels",
      navigation: `/mission-levels/${level_id}`,
    },
    {
      title: missionDetail.title,
      navigation: `/mission-levels/${level_id}/quest/${mission_id}`,
    },
    {
      title: data?.main_heading,
      navigation: ``,
      active: true,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="mission-level-heading">
        <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
      </div>
      <div className="row">
        <div className="col-12">
          <QuestDayTab
            schedule={data}
            missionContentSettings={missionContentSettings}
            playedContent={true}
          />
        </div>
      </div>
    </div>
  );
}
