import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress, Tooltip, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { htmlDecode } from "src/utils/convertHtml";
import MissionLevelsScheduleTab from "../../components/MissionLevels/MissionLevelsScheduleTab";
// import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
// import UnlockVideo from "src/components/MissionLevels/UnlockVideo";
// import {
//   mission_level_detail_api,
//   start_mission_api,
//   unlock_mission_schedule_api,
// } from "src/DAL/MissionLevels/MissionLevels";
import { s3baseUrl } from "src/config/config";
// import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import // dd_date_format,
// dd_date_format_UPDATE,
// handle_change_page,
// number_with_comma,
"src/utils/constants";
// import StartMission from "src/components/MissionLevels/StartMission";
import moment from "moment";
import MissionLevelsOverviewTab from "src/components/MissionLevels/MissionLevelsOverviewTab";
import ReactVideoPlayer from "src/components/_dashboard/GeneralComponents/ReactVideoPlayer";
import MissionCardCompleteForReport from "src/components/MissionLevels/MissionCardCompleteForReport";
// import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
// import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionLevelDetail({
  hideHeading,
  missionBadges,
  missionDetail,
}) {
  const { mission_id, level_id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMissionConfirm, setOpenMissionConfirm] = useState(false);
  const [openUnlock, setOpenUnlock] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unLockCoin, setUnLockCoin] = useState(0);
  const [userCoin, setUserCoin] = useState(0);
  const [openQuestionsPopup, setOpenQuestionsPopup] = useState(false);
  const [memberShipLOckedStatus, setMembrShipLOckedStatus] = useState(null);
  const [upgradeButton, setUpgradeButton] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (schedule) => {
    navigate(
      `/mission-levels/${level_id}/mission/${mission_id}/schedule/${schedule._id}`,
      {
        state: schedule,
      }
    );
  };

  const handleClickStart = () => {
    // if (missionDetail.mission_locked) {
    //   enqueueSnackbar(missionDetail.mission_locked_reason, {
    //     variant: "error",
    //   });
    //   return;
    // }
    // setOpenUnlock(2);
  };

  const handleCrossUnlock = () => {
    setSelectedSchedule({});
    setOpenUnlock(null);
  };

  // const get_mission_detail = async () => {
  //   const result = await mission_level_detail_api(
  //     mission_id ?? "66a722347421ac375573836f"
  //   );
  //   if (result.code == 200) {
  //     setMissionDetail({ ...result.mission, user_data: result.user_data });
  //     setMembrShipLOckedStatus(result.membership_upgrade_button_setting);
  //     setSchedules(result.mission_schedules);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     navigate("/mission-levels");
  //     setIsLoading(false);
  //   }
  // };

  // const handleStartMission = async () => {
  //   setIsSubmitting(true);
  //   const result = await start_mission_api(
  //     mission_id ?? "66a722347421ac375573836f"
  //   );
  //   if (result.code == 200) {
  //     let mission_status = "in_progress";
  //     let user_data = result.user_data;
  //     setMissionDetail((old) => ({ ...old, mission_status, user_data }));
  //     setSchedules(result.userMission.mission_schedules);
  //     enqueueSnackbar(result.message, { variant: "success" });
  //     setIsLoading(false);
  //     navigate(`/mission-levels/level-details/${mission_id}`);

  //     setOpenMissionConfirm(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setOpenMissionConfirm(false);
  //     setIsLoading(false);
  //   }
  // };

  // const handleUnlock = (unlock_coin, user_coin) => {
  //   if (user_coin < unlock_coin) {
  //     enqueueSnackbar("You do not have enough coins to start Schedule", {
  //       variant: "error",
  //     });
  //     return;
  //   }
  //   setOpenConfirm(true);
  //   setUnLockCoin(unlock_coin);
  //   setUserCoin(user_coin);
  //   setTimeout(() => {
  //     setOpenUnlock(null);
  //   }, 500);
  // };

  const handleOpenQuestionsPopup = () => {
    setOpenQuestionsPopup(true);
  };

  const handleMissionStart = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start this Mission", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenMissionConfirm(true);
    setTimeout(() => {
      setOpenUnlock(null);
    }, 100);
  };

  const TABS_DATA = {
    handleClick,
    schedules: missionDetail.mission_schedules,
    missionDetail,
    handleClickStart,
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin">
          {!hideHeading && (
            <div className="col-12">
              <div className="d-flex challenge-video-detail-title">
                <span>
                  <IconButton
                    className="back-screen-button"
                    onClick={() => navigate(`/missions`)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </span>
                <h2 className="ms-2">{htmlDecode(missionDetail.title)}</h2>
              </div>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className={`col-12 ${"col-md-8"}`}>
            <div id="video_detail_image_box">
              {missionDetail.video_url ? (
                <ReactVideoPlayer url={missionDetail.video_url} />
              ) : (
                <img
                  src={s3baseUrl + missionDetail.image?.thumbnail_1}
                  className="img-fluid w-100"
                />
              )}
            </div>
          </div>

          <div className="col-md-4">
            <MissionCardCompleteForReport
              missionDetail={missionDetail}
              missionBadges={missionBadges}
              hideEranedBadges={true}
              // missionDashboaerd={missionDashboaerd}
            />
          </div>

          {/* <div className="col-12">
            {!missionDetail.content_settings.allow_user_to_start_mission &&
              missionDetail.content_settings
                ?.user_unable_to_start_mission_discription && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      missionDetail.content_settings
                        ?.user_unable_to_start_mission_discription,
                  }}
                  className="level-not-match mt-3"
                ></div>
              )}
          </div> */}

          {(missionDetail.detailed_description ||
            (missionDetail.mission_status === "not_started" &&
              missionDetail.content_settings?.start_mission_button_text)) && (
            <div className="col-12">
              <MissionLevelsOverviewTab {...TABS_DATA} />
            </div>
          )}
          {missionDetail.mission_schedules.length > 0 && (
            <div className="col-md-12  mt-3">
              <div className="row">
                <div
                  className="col-12 schedule-heading pb-0"
                  id="schedule-heading-box"
                >
                  <h2 style={{ fontSize: "20px" }} className="mt-2 mb-2">
                    {missionDetail.content_settings?.schedule_heading}
                  </h2>
                </div>
              </div>
              <MissionLevelsScheduleTab {...TABS_DATA} missionSchedules={{}} />
            </div>
          )}
          {/* {memberShipLOckedStatus &&
            memberShipLOckedStatus?.show_membership_button &&
            memberShipLOckedStatus?.upgrade_button_url &&
            memberShipLOckedStatus?.upgrade_button_text && (
              <div className="col-12">
                <button
                  className="mc-button-contained floating-button"
                  onClick={() =>
                    handle_change_page(
                      memberShipLOckedStatus?.upgrade_button_url,
                      setUpgradeButton
                    )
                  }
                >
                  {upgradeButton
                    ? "Please Wait..."
                    : memberShipLOckedStatus?.upgrade_button_text}
                </button>
              </div>
            )} */}
          {/* {!memberShipLOckedStatus?.show_membership_button &&
            missionDetail.content_settings.allow_user_to_start_mission &&
            missionDetail.mission_status === "not_started" && (
              <div className="col-12">
                <button
                  className="mc-button-contained floating-button"
                  onClick={handleClickStart}
                >
                  {missionDetail.content_settings?.start_mission_button_text ||
                    "Start Mission"}
                </button>
              </div>
            )} */}
          {/* <div className="col-12 mt-3 d-block d-md-none">
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
              className="challenge-video-detail-tabs"
            />
          </div> */}
        </div>
      </div>
      {/* <GeneralModelBox
        open={Boolean(openUnlock)}
        setOpen={setOpenUnlock}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <>
            {openUnlock == 1 ? (
              <UnlockVideo
                handleCross={handleCrossUnlock}
                selectedSchedule={selectedSchedule}
                handleUnlock={handleUnlock}
                isSubmitting={isSubmitting}
              />
            ) : (
              openUnlock && (
                <StartMission
                  handleCross={handleCrossUnlock}
                  missionDetail={missionDetail}
                  handleUnlock={handleMissionStart}
                  isSubmitting={isSubmitting}
                />
              )
            )}
          </>
        }
      /> */}
      {/* <WhatsappPopUpModel
        open={Boolean(openQuestionsPopup)}
        setOpen={setOpenQuestionsPopup}
        title={"Mission  Questions"}
        show_date_and_income={true}
        componentToPassDown={
          <GeneralQuestionsList
            created_for="mission_onboarding"
            created_for_id={mission_id}
            hideSubmitButton={true}
            remove_back_button={true}
            hide_dynamite_reply={true}
            for_unlock_mission={true}
            prevent_single_answer={true}
            handleSubmitMissionFinal={handleStartMission}
            className="challenge-video-days-questions"
            collapseAll={true}
            // for_mission={true}
            submit_button_text={"Start Mission"}
            // onSubmitSuccess={handleSuccess}
          />
        }
      /> */}

      {/* <CustomConfirmation
        open={openConfirm}
        setOpen={setOpenConfirm}
        title={`Unlocking this Schedule will leave you with a remaining balance of ${number_with_comma(
          userCoin - unLockCoin
        )} coins. Are you sure you want to proceed?`}
        handleAgree={handleConfirmSubmit}
        isLoading={isSubmitting}
      />

      <CustomConfirmation
        open={openMissionConfirm}
        setOpen={setOpenMissionConfirm}
        title={
          unLockCoin <= 0
            ? "Are you sure you want to proceed?"
            : `Starting this Mission will leave you with a remaining balance of ${number_with_comma(
                userCoin - unLockCoin
              )} coins. Are you sure you want to proceed?`
        }
        handleAgree={() => {
          if (
            missionDetail?.content_settings
              ?.show_mission_on_boarding_questions &&
            missionDetail?.question_exist
          ) {
            handleOpenQuestionsPopup();
          } else {
            handleStartMission();
          }
        }}
        isLoading={isSubmitting}
      /> */}
    </>
  );
}
