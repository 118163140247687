import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  delete_feed_action,
  get_feeds_detail,
  community_feed_action_api,
  approve_feed_api,
} from "src/DAL/Community/Community";
import { projectMode, s3baseUrl } from "src/config/config";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import ShowAllLikes from "./ShowAllLikes";
import {
  count_chars,
  FEED_TXET_LIMIT,
  getImages,
  post_description,
  replaceUserNamesWithHTML,
  string_avatar,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";
import { FeedComments, VideoStream } from "./components";
import CreatePost from "./CreatePost";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import FeedImageSlider from "src/components/GoalStatement/FeedImageSlider";
import UserInfoCard from "./components/LikesPopup/UserInfoCard";
import EventInfoForPost from "./components/LikesPopup/EventInfoForPost";
import ActionsCount from "./components/LikesPopup/ActionsCount";
import HandleAction from "./components/LikesPopup/HandleAction";
import ReactVideoPlayer from "src/components/ReactPlayers/ReactVideoPlayer";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import PollFeed from "./components/FeedPoll/PollFeed";
import FeedPollResult from "./components/FeedPoll/FeedPollResult";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import SurveyFeed from "./components/SurveyFeed/SurveyFeed";
import FeedSurveyResult from "./components/SurveyFeed/FeedSurveyResult";

export default function FeedDetailPopup({
  handleCloseDetailBox,
  post_id,
  handleINternalNoteClient = () => {},
  feed,
  getFeedsListingOnAddFeed = () => {},
  handleSuccessDelete = () => {},
  handleINternalNote = () => {},
  handleSuccessUpdate = () => {},
  event_id,
  openDetailModal,
  selectedImageIndex = 0,
  list_type,
  is_detail_page,
  handleUpdatePoll,
  setFeedAppearBy = () => {},
  feedAppearBy = "general",
  searchFeeds = {},
}) {
  const settings = useContentSetting();
  const { socket, adminTimeZone, consultantInfo } = settings;
  const { pathname } = useLocation();
  const [showTab, setShowTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [feedDetail, setFeedDetail] = useState(feed);
  const [isLiked, setIsLiked] = useState(feed.is_liked);
  const [topLikedUser, setTopLikedUser] = useState(feed.top_liked_user);
  const [feedType, setFeedType] = useState(feed.feed_type);
  const [likeCount, setLikeCount] = useState(feed.like_count);
  const [formAction, setFormAction] = useState("EDIT");
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [isShowMore, setIsShowMore] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [commentCountUpdated, setCommentCountUpdated] = useState(feed);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showGlow, setShowGlow] = useState(true);
  const live_event = window.location.pathname.includes("/live-events/");
  const my_event = window.location.pathname.includes("/my-events/");
  const [openPoll, setOpenPoll] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState("");

  //Changing tab values
  const getFeedsDeteil = async () => {
    const result = await get_feeds_detail(post_id);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      setCommentCountUpdated(result.feeds);
      setIsLiked(result.feeds.is_liked);
      setTopLikedUser(result.feeds.top_liked_user);
      setLikeCount(result.feeds.like_count);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
    setShowTab(1);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
    setShowTab();
  };
  const handleClose = () => {
    setOpenPoll(false);
    setOpenSurvey(false);
  };

  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);
    const result = await community_feed_action_api(formData);
    if (result.code === 200) {
      if (action === "feedlike" || action === "feedunlike") {
        setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
      }
      const socketData = {
        action,
        feed_id: id,
        token: localStorage.getItem("token"),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };

      if (list_type == "the_cosmos") {
        socket.emit("delegate_feed_room_action_event", socketData);
      } else {
        socket.emit("feed_room_action_event", socketData);
      }
    } else {
      if (action === "feedlike") {
        setIsLiked(false);
      } else if (action === "feedunlike") {
        setIsLiked(true);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleFeedEdit = () => {
    setFormAction("EDIT");
    setOpenCreateModal(true);
  };

  const handleAgreeDelete = (value) => {
    setFeedId(value._id);
    setOpenDelete(true);
  };

  const handleAgreePublish = (value) => {
    setFeedId(value._id);
    setOpenConfirmation("approve");
  };

  const handleAgree = async () => {
    setOpenDelete(false);
    const result = await delete_feed_action(feedId);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      setOpenDelete(false);
      handleSuccessDelete(feed);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpdateFeedStatus = async () => {
    setOpenConfirmation("");
    let result = await approve_feed_api(feedId);
    if (result.code === 200) {
      handleSuccessDelete(feed);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      adminTimeZone
    );
  };

  const EDIT_DELETE = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleFeedEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const MENU_OPTIONS = [];
  if (feedDetail.feed_type === "poll") {
    if (feedDetail.poll_info.poll_status !== "expired") {
      MENU_OPTIONS.push(...EDIT_DELETE);
    }
  } else if (feedDetail.feed_type === "survey") {
    if (feedDetail.survey_info.survey_status !== "expired") {
      MENU_OPTIONS.push(...EDIT_DELETE);
    }
  } else {
    MENU_OPTIONS.push(...EDIT_DELETE);
  }
  if (live_event || my_event) {
    MENU_OPTIONS.push({
      label: "Add As Personel Notes",
      icon: "akar-icons:edit",
      handleClick: handleINternalNote,
    });
  }
  const handleUpdateFeed = (feed) => {
    setFeedDetail(feed);
    if (handleUpdatePoll) {
      handleUpdatePoll(feed);
    }
  };
  const handlePollDetail = (feed) => {
    setOpenPoll(true);
  };

  const handleSurveyDetail = (feed) => {
    setOpenSurvey(true);
  };

  const handleMenuClient = (row) => {
    let MENU_OPTIONS_CLIENT = [];
    if (live_event || my_event) {
      MENU_OPTIONS_CLIENT.push({
        label: "Add As Personel Notes",
        icon: "akar-icons:edit",
        handleClick:
          row.action_info?.action_by == "member_user"
            ? handleINternalNoteClient
            : handleINternalNote,
      });
    }
    return MENU_OPTIONS_CLIENT;
  };

  const handle_poll_answered = (data) => {
    setFeedDetail((old) => {
      let poll_info = { ...old.poll_info };
      let selected_options = [];
      if (old.selected_options?.length > 0) {
        selected_options = [...old.selected_options];
      }
      if (old._id == data.feed_obj._id) {
        poll_info = data.feed_obj.poll_info;
        if (data.action_by._id === settings.userInfo._id) {
          selected_options = data.feed_obj.selected_options;
        }
      }
      return { ...old, poll_info, selected_options };
    });
  };

  const handle_poll_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedDetail((old) => {
        let poll_info = old.poll_info;
        poll_info.poll_status = "expired";
        return { ...old, poll_info };
      });
    }
  };

  const handle_survey_answered = (data) => {
    setFeedDetail((old) => {
      let survey_info = { ...old.survey_info };
      let selected_options = [];
      if (old.selected_options?.length > 0) {
        selected_options = [...old.selected_options];
      }
      if (old._id == data.feed_obj._id) {
        survey_info = data.feed_obj.survey_info;
        if (data.action_by._id === settings.userInfo._id) {
          selected_options = data.feed_obj.selected_options;
        }
      }
      return { ...old, survey_info, selected_options };
    });
  };

  const handle_survey_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedDetail((old) => {
        let survey_info = old.survey_info;
        survey_info.survey_status = "expired";
        return { ...old, survey_info };
      });
    }
  };

  useEffect(() => {
    setShowGlow(true);
    setTimeout(() => {
      setShowGlow(false); // Hide the image after 3 seconds
    }, 3000);
    getFeedsDeteil();
  }, [post_id]);

  const MENU_OPTIONS_REVIEW = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Approve",
      icon: "ix:to-be-published",
      handleClick: handleAgreePublish,
    },
  ];

  useEffect(() => {
    if (openDetailModal) {
      let path = `${pathname}/feed-detail/${post_id}`;
      window.history.replaceState(null, null, path);

      // for close on escape
      window.onkeyup = function (event) {
        if (event.keyCode == 27) {
          handleCloseDetailBox();
        }
      };

      return () => {
        window.history.replaceState(null, null, pathname);
      };
    }
  }, []);

  const update_feed_likes_by_socket = (data) => {
    setLikeCount(data.action_response.like_count);
    setTopLikedUser(data.action_response.top_liked_user);
    if (settings.userInfo._id == data.action_by) {
      setIsLiked(data.action_response.is_liked);
    }
  };

  useEffect(() => {
    socket.emit("delegate_feed_room", "delegate_live_feed_room");
    socket.emit("live_event_room", "live_feed_room");

    socket.on("live_feed_room_reciever", (data) => {
      if (data.action === "feedlike" || data.action === "feedunlike") {
        update_feed_likes_by_socket(data);
      } else if (data.action === "add_comment") {
        setFeedDetail((old) => {
          return {
            ...old,
            comment_count: old.comment_count + 1,
          };
        });
      } else if (
        data.action === "delete_comment_reply" ||
        data.action === "delete_comment"
      ) {
        getFeedsDeteil();
      } else if (data.action === "poll_answered") {
        handle_poll_answered(data);
      } else if (data.action === "poll_expired") {
        handle_poll_expired(data);
      } else if (data.action === "survey_answered") {
        handle_survey_answered(data);
      } else if (data.action === "survey_expired") {
        handle_survey_expired(data);
      }
    });
    socket.on("delegate_live_feed_room_reciever", (data) => {
      if (data.action === "feedlike" || data.action === "feedunlike") {
        update_feed_likes_by_socket(data);
      } else if (data.action === "add_comment") {
        setFeedDetail((old) => {
          return {
            ...old,
            comment_count: old.comment_count + 1,
          };
        });
      } else if (
        data.action === "delete_comment_reply" ||
        data.action === "delete_comment"
      ) {
      }
    });

    return () => {
      socket.off("delegate_live_feed_room_reciever");
      socket.off("live_feed_room_reciever");
    };
  }, [openDetailModal, post_id]);

  useEffect(() => {
    socket.on("end_stream_event_listener", (payload) => {
      if (payload.feed_id === post_id) {
        enqueueSnackbar("Stream ended.", { variant: "info" });
        handleCloseDetailBox();
      }
    });

    return () => {
      socket.off("end_stream_event_listener");
    };
  }, [post_id]);

  return (
    <div>
      <div className="row post-detail-box">
        {feedDetail.is_reward_feed &&
          showGlow &&
          feedDetail.reward_data?.reward_feed_gif && (
            <div className="gif-image">
              <img src={s3baseUrl + feedDetail.reward_data?.reward_feed_gif} />
            </div>
          )}
        {feedDetail?.feed_type !== "general" &&
          (feedDetail.feed_images?.length > 0 ||
            feedDetail.video_url ||
            feedDetail?.is_live_streaming ||
            feedDetail.embed_code) && (
            <div className="col-12 col-md-8">
              {feedDetail.feed_type === "image" &&
                feedDetail.feed_images &&
                feedDetail.feed_images.length > 0 && (
                  <div className="m-2 m-md-5">
                    <FeedImageSlider
                      data={getImages(feedDetail.feed_images)}
                      selectedIndex={selectedImageIndex}
                    />
                  </div>
                )}
              {feedDetail.feed_type === "embed_code" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: feedDetail.embed_code,
                  }}
                  className="feed-image m-2 m-md-5"
                ></div>
              )}
              {feedDetail?.feed_type === "video" && feedDetail?.video_url && (
                <div className="feed-image m-2 m-md-5">
                  <ReactVideoPlayer url={feedDetail.video_url} />
                </div>
              )}

              {feedDetail?.feed_type === "live" &&
                (projectMode === "DEV" ? (
                  <div className="">
                    {feedDetail?.is_live_streaming ? (
                      <VideoStream
                        feedDetail
                        post_id={post_id}
                        thumbnail={feedDetail?.image?.thumbnail_1}
                        openDetailModal={openDetailModal}
                      />
                    ) : (
                      <div className="feed-image m-2 m-md-5">
                        <img src={s3baseUrl + feedDetail?.image?.thumbnail_1} />
                      </div>
                    )}
                  </div>
                ) : (
                  feedDetail?.embed_code && (
                    <div className="">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feedDetail?.embed_code,
                        }}
                        className="feed-image m-2 m-md-5"
                      ></div>
                    </div>
                  )
                ))}
            </div>
          )}
        <div
          className={`col-12 mt-5 mt-md-0 mx-auto profile-cards p-3 mb-3 main-section ${
            feedDetail?.feed_type !== "general" &&
            (feedDetail.feed_images?.length > 0 ||
              feedDetail.video_url ||
              feedDetail?.is_live_streaming ||
              feedDetail.embed_code)
              ? "col-md-4"
              : "col-md-6"
          }`}
        >
          <div className="d-flex w-100 justify-content-between">
            <UserInfoCard
              profile_image={s3baseUrl + feedDetail.action_info?.profile_image}
              user_name={htmlDecode(feedDetail.action_info?.name)}
              avatar_char={string_avatar(feedDetail.action_info?.name)}
              date={feedDetail?.createdAt}
              activity_type={null}
              badge_level_info={feedDetail.badge_level_info}
            />
            <div className="d-flex">
              {/* {feedDetail.badge_level_info && (
                <Tooltip title={feedDetail.badge_level_info.title}>
                  <img
                    src={
                      s3baseUrl + feedDetail.badge_level_info.icon?.thumbnail_1
                    }
                    className="dynamite-level-image"
                  />
                </Tooltip>
              )} */}
              {list_type === "the_cosmos" ? (
                <Tooltip
                  title={
                    consultantInfo[
                      `${feedDetail.created_for_level_or_type}_feed_label`
                    ]
                      ? consultantInfo[
                          `${feedDetail.created_for_level_or_type}_feed_label`
                        ]
                      : feedDetail.created_for_level_or_type
                  }
                >
                  <img
                    src={
                      consultantInfo[
                        `${feedDetail.created_for_level_or_type}_feed_icon`
                      ]
                        ? s3baseUrl +
                          consultantInfo[
                            `${feedDetail.created_for_level_or_type}_feed_icon`
                          ]
                        : ""
                    }
                    className="dynamite-level-image"
                  />
                </Tooltip>
              ) : (
                <>
                  {feedDetail.badge_level_info && (
                    <Tooltip title={feedDetail.badge_level_info.title}>
                      <img
                        src={
                          s3baseUrl +
                          feedDetail.badge_level_info.icon?.thumbnail_1
                        }
                        className="dynamite-level-image"
                      />
                    </Tooltip>
                  )}
                </>
              )}
              {(!feedDetail.is_publish || feedDetail.is_publish == "false") && (
                <Tooltip
                  title={`post will be published on ${get_start_end_time(
                    feedDetail.schedule_date_time
                  )} (Europe/Dublin)`}
                >
                  <UpdateIcon />
                </Tooltip>
              )}
              {feedDetail?.is_self ? (
                <div className="poster-logo text-end">
                  <CustomPopover menu={MENU_OPTIONS} data={feedDetail} />
                </div>
              ) : live_event || my_event ? (
                <CustomPopover
                  menu={handleMenuClient(feedDetail)}
                  data={feedDetail}
                />
              ) : list_type === "review" ? (
                <CustomPopover menu={MENU_OPTIONS_REVIEW} data={feedDetail} />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="mt-2 post-description">
            <p
              dangerouslySetInnerHTML={{
                __html: urlify(
                  count_chars(feedDetail.description) > FEED_TXET_LIMIT &&
                    isShowMore
                    ? post_description(
                        replaceUserNamesWithHTML(
                          feedDetail.description,
                          feedDetail.mentioned_users
                        ),
                        FEED_TXET_LIMIT
                      )
                    : replaceUserNamesWithHTML(
                        feedDetail.description,
                        feedDetail.mentioned_users
                      )
                ),
              }}
            ></p>
            {count_chars(feedDetail.description) > FEED_TXET_LIMIT && (
              <span
                className="ms-2"
                onClick={() => {
                  setIsShowMore(!isShowMore);
                }}
              >
                {isShowMore ? "See More" : "See Less"}
              </span>
            )}

            {list_type === "review" && (
              <div className="review-reason-popup">
                <h3>Review Reason</h3>
                <p>{feedDetail.review_info?.reason}</p>
              </div>
            )}
          </div>
          {feedDetail.feed_type === "poll" && feedDetail.poll_info && (
            <PollFeed
              feed={feedDetail}
              handleUpdateFeed={handleUpdateFeed}
              handlePollDetail={handlePollDetail}
              searchFeeds={searchFeeds}
            />
          )}
          {feedDetail.feed_type === "survey" && feedDetail.survey_info && (
            <SurveyFeed
              feed={feedDetail}
              handleUpdateFeed={handleUpdateFeed}
              handleSurveyDetail={handleSurveyDetail}
              searchFeeds={searchFeeds}
            />
          )}
          {feedDetail.event_info?.is_event_info && (
            <EventInfoForPost feed={feedDetail} />
          )}

          {searchFeeds?.feed_action_type !== "reported" &&
            feedDetail.is_publish && (
              <>
                <ActionsCount
                  handleOpenLikedBox={handleOpenLikedBox}
                  likeCount={likeCount}
                  topLikedUser={topLikedUser}
                  commentsCount={feedDetail.comment_count}
                  commentCountUpdated={commentCountUpdated.comment_count}
                />
                <hr className="mt-0" />
                <HandleAction
                  isLiked={isLiked}
                  handleFeedAction={handleFeedAction}
                  feed_id={feedDetail._id}
                />
                <hr />
              </>
            )}

          {searchFeeds?.feed_action_type !== "reported" && (
            <FeedComments
              is_popup={openDetailModal}
              feed_id={post_id}
              getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
              handleCloseDetailBox={handleCloseDetailBox}
              list_type={list_type}
              is_detail_page={is_detail_page}
              getFeedsDeteil={getFeedsDeteil}
              feed={feedDetail}
              event_id={event_id}
            />
          )}
        </div>
        {setOpenCreateModal && (
          <CreatePost
            setFeedType={setFeedType}
            feedType={feedType}
            openCreateModal={openCreateModal}
            setOpenCreateModal={setOpenCreateModal}
            getFeedsListing={getFeedsListingOnAddFeed}
            userInfo={settings.userInfo}
            selectedFeed={feed}
            formAction={formAction}
            setFormAction={setFormAction}
            handleSuccessUpdate={handleSuccessUpdate}
            event_id={event_id}
            list_type={list_type}
            setFeedAppearBy={setFeedAppearBy}
            feedAppearBy={feedAppearBy}
          />
        )}
        {openLikesModal && (
          <ShowAllLikes
            openLikesModal={openLikesModal}
            setOpenLikesModal={setOpenLikesModal}
            handleCloseSimpleBox={handleCloseSimpleBox}
            showTab={showTab}
            post_id={post_id}
            handleCloseDetailBox={handleCloseDetailBox}
          />
        )}
        <GeneralModelBox
          open={openPoll}
          setOpen={setOpenPoll}
          className="feed-poll-popup-modal"
          componentToPassDown={
            <FeedPollResult selectedFeed={feed} handleClosePoll={handleClose} />
          }
        />
        <GeneralModelBox
          open={openSurvey}
          setOpen={setOpenSurvey}
          className="feed-poll-popup-modal"
          componentToPassDown={
            <FeedSurveyResult
              selectedFeed={feed}
              handleCloseSurvey={handleClose}
            />
          }
        />
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this post?"}
          handleAgree={handleAgree}
        />
        <CustomConfirmation
          open={Boolean(openConfirmation)}
          setOpen={setOpenConfirmation}
          title={`Are you sure you want to ${openConfirmation} this post?`}
          handleAgree={handleUpdateFeedStatus}
        />
      </div>
    </div>
  );
}
