import React, { useState } from "react";
import {
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useSnackbar } from "notistack";
import { list_members_and_programs_v2 } from "src/DAL/Groups/Groups";

export default function GroupFilter({
  filterData,
  searchSubmitFilter,
  handleClearFilter,
  setFilterData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [programsList, setProgramsList] = useState([]);
  const [badgeLevels, setBadgeLevels] = useState([]);
  const [customSearch, setCustomSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getProgrammesList = async () => {
    let postData = {
      search: customSearch,
      type: filterData?.group_by,
    };
    const result = await list_members_and_programs_v2(postData);
    if (result.code === 200) {
      setBadgeLevels(result?.badge_levels ?? []);
      if (filterData?.group_by == "sale_page") {
        let sale_pages = result.data.map((sale_page) => {
          let page_type = sale_page.type !== "template" ? "" : "| Template";
          return {
            ...sale_page,
            sale_page_title: sale_page?.sale_page_title + " " + page_type,
            chip_label: sale_page?.sale_page_title + " " + page_type,
            chip_value: sale_page?._id,
          };
        });
        setProgramsList(sale_pages);
      } else {
        let new_data = result.data.map((data) => {
          return {
            ...data,
            chip_label: data?.title,
            chip_value: data?._id,
          };
        });
        setProgramsList(new_data);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (value, fieldName) => {
    setFilterData((values) => ({ ...values, [fieldName]: value }));
  };

  let badge_levels_array = badgeLevels.map((level) => {
    return {
      ...level,
      chip_label: level.title,
      chip_value: level._id,
    };
  });

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getProgrammesList();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [customSearch]);

  useEffect(() => {
    if (filterData?.group_by) {
      getProgrammesList();
    }
    setCustomSearch("");
  }, [filterData?.group_by]);

  return (
    <>
      <div className="container-fluid new-memories">
        <div className="mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Group By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="group_by"
              value={filterData?.group_by}
              label="Group By"
              onChange={handleChange}
            >
              <MenuItem value="program">Programme</MenuItem>
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="sale_page">Sale Page</MenuItem>
              <MenuItem value="mission">Missions</MenuItem>
            </Select>
          </FormControl>
        </div>

        {filterData?.group_by == "program" && (
          <div className="mt-3">
            <MUIAutocomplete
              inputLabel="Programmes"
              selectedOption={filterData?.programsName ?? []}
              setSelectedOption={(value) =>
                handleChangeOthers(value, "programsName")
              }
              optionsList={programsList}
              setCustomSearch={setCustomSearch}
              multiple
              name="title"
              limitTags={2}
            />
          </div>
        )}

        {filterData?.group_by == "event" && (
          <div className="mt-3">
            <MUIAutocomplete
              inputLabel="Events"
              selectedOption={filterData?.eventsName ?? []}
              setSelectedOption={(value) =>
                handleChangeOthers(value, "eventsName")
              }
              optionsList={programsList}
              setCustomSearch={setCustomSearch}
              multiple
              name="title"
              limitTags={2}
            />
          </div>
        )}

        {filterData?.group_by == "sale_page" && (
          <div className="mt-3">
            <MUIAutocomplete
              inputLabel="Sale Pages"
              selectedOption={filterData?.salePageName ?? []}
              setSelectedOption={(value) =>
                handleChangeOthers(value, "salePageName")
              }
              optionsList={programsList}
              setCustomSearch={setCustomSearch}
              multiple
              name="sale_page_title"
              limitTags={2}
            />
          </div>
        )}
        {filterData?.group_by == "mission" && (
          <div className="mt-3">
            <MUIAutocomplete
              inputLabel="Missions"
              selectedOption={filterData?.missionsName ?? []}
              setSelectedOption={(value) =>
                handleChangeOthers(value, "missionsName")
              }
              optionsList={programsList}
              setCustomSearch={setCustomSearch}
              multiple
              name="title"
              limitTags={2}
            />
          </div>
        )}

        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Badge Levels"
            selectedOption={filterData?.badge_levels ?? []}
            setSelectedOption={(value) =>
              handleChangeOthers(value, "badge_levels")
            }
            optionsList={badge_levels_array}
            multiple
            name="title"
          />
        </div>

        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
