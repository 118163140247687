import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import DayVideoTab from "src/components/MissionLevels/DayVideoTab";
import { dd_date_format_UPDATE } from "src/utils/constants";
import moment from "moment";
import { mission_schedule_detail_api } from "src/DAL/Missions/Missions";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ScheduleDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(0);
  const [missionDetail, setMissionDetail] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const { level_id, schedule_id, mission_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [missionResult, setMissionResult] = useState({});
  const [missionContentSettings, setMissionContentSettings] = useState(null);
  const [rewardData, setRewardData] = useState({});
  const [hideSubmitButton, setHideSubmitButton] = useState(false);
  const [played, setPlayed] = useState(false);
  const [data, setData] = useState(null);
  const [showQuestionsSreen, setShowQuestionsSreen] = useState(0);
  const [isLoadingDataOnScreen, setIsLoadingDataOnScreen] = useState(false);

  const get_mission_detail = async () => {
    setIsLoading(true);
    const result = await mission_schedule_detail_api(schedule_id);
    if (result.code == 200) {
      setMissionResult(result);
      setRewardData(result.mission.content_settings);
      setMissionContentSettings(result.mission.content_settings);
      setMissionDetail({ ...result.mission, user_data: result.user_data });

      let find_schedule = result.mission_schedule;
      setPlayed(find_schedule.content_watched);

      const find_gratitude_growth_tool_object =
        find_schedule.completed_allowed_actions &&
        find_schedule.completed_allowed_actions.length > 0 &&
        find_schedule.completed_allowed_actions.find(
          (item) => item.type == "dynamite_dairy"
        );
      const find_gratitude_growth_tool = find_gratitude_growth_tool_object
        ? find_gratitude_growth_tool_object.gratitude_options
        : [];

      let data = {
        ...find_schedule,
        schedule_questions:
          find_schedule.schedule_questions &&
          find_schedule.schedule_questions.length > 0
            ? find_schedule.schedule_questions.map((item) => {
                return {
                  ...item,
                  answer_statement: item.answer_statement || "",
                };
              })
            : [],
        general_allowed_actions:
          find_schedule.general_allowed_actions &&
          find_schedule.general_allowed_actions.length > 0
            ? find_schedule.general_allowed_actions.map((item) => {
                return {
                  ...item,
                };
              })
            : [],
        growth_tool_allowed_actions:
          find_schedule.growth_tool_allowed_actions &&
          find_schedule.growth_tool_allowed_actions.length > 0
            ? find_schedule.growth_tool_allowed_actions
                .map((item) => {
                  return {
                    ...item,
                    growth_tool_data:
                      item.growth_tool_data &&
                      Object.keys(item.growth_tool_data).length > 0
                        ? item.growth_tool_data
                        : {
                            statement_array: [
                              {
                                option: "",
                              },
                              {
                                option: "",
                              },
                              {
                                option: "",
                              },
                            ],
                          },
                  };
                })
                .concat(
                  find_schedule.growth_tool_allowed_actions.map((item) => {
                    return {
                      ...item,
                      tool: "gratitude_daily",
                      growth_tool_data:
                        item.growth_tool_data &&
                        Object.keys(item.growth_tool_data).length > 0
                          ? {
                              ...item.growth_tool_data,
                              statement_array:
                                find_gratitude_growth_tool.length > 0
                                  ? find_gratitude_growth_tool
                                  : item.growth_tool_data.statement_array,
                            }
                          : {
                              statement_array: [
                                {
                                  option: "",
                                },
                                {
                                  option: "",
                                },
                                {
                                  option: "",
                                },
                              ],
                            },
                    };
                  })
                )
            : [],
      };

      setData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      handleBack();
    }
  };

  const handleBack = () => {
    if (showQuestionsSreen == 1) {
      setShowQuestionsSreen(0);
      localStorage.removeItem("showQuestionsSreen", 0);
      return;
    } else if (showQuestionsSreen == 2) {
      navigate(`/mission-levels/level-details/${schedule_id}`);
      localStorage.removeItem("showQuestionsSreen", 0);
    } else {
      navigate(-1);
      localStorage.removeItem("showQuestionsSreen", 0);
    }
  };

  const handleClick = (schedule) => {
    const today = moment();
    const startedAt = moment(schedule.started_at);
    setSelectedSchedule(schedule);
    if (schedule.schedule_status === "locked") {
      if (startedAt.isAfter(today, "day")) {
        let error = `You can't unlock it before ${dd_date_format_UPDATE(
          schedule.started_at
        )}`;
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
    }
    setSelectedSchedule(schedule);
    setOpenModal(3);
  };

  const handleChangeAction = (id, isDisabled, schedule, action) => {
    if (isDisabled) return;
    // if (action.is_checked) {
    //   return;
    // }
    const ObjectToPassDown = {
      id,
      isDisabled,
      schedule,
      action,
    };
    if (action.action_type == "general") {
      complete_general_action(ObjectToPassDown);
    } else {
      complete_growth_tool_action(ObjectToPassDown);
    }
  };

  const handleActionSubmit = async (data) => {};

  const complete_general_action = (data) => {
    const { id, schedule } = data;
    let tab_data = { ...schedule };
    tab_data.general_allowed_actions.map((item) => {
      if (item._id === id) {
        let is_checked = Boolean(item.is_checked);
        item.is_checked = !is_checked;
      }
      return item;
    });
    setData(tab_data);
    let action = schedule.general_allowed_actions.find(
      (item) => item._id === id
    );
    const postData = {
      action: {
        _id: action._id,
        type: action.action_type,
        statement: action.statement,
      },
      mission_schedule_id: schedule._id,
    };

    handleActionSubmit(postData);
  };
  const complete_growth_tool_action = (data) => {
    const { id, schedule } = data;

    let action = schedule.growth_tool_allowed_actions.find(
      (item) => item._id === id
    );
    for (let item of action.growth_tool_data.statement_array) {
      if (!item.option) {
        enqueueSnackbar("All options must be completed", { variant: "error" });
        return;
      }
    }
    let tab_data = { ...schedule };
    tab_data.growth_tool_allowed_actions.map((item) => {
      if (item._id === id) {
        let is_checked = Boolean(item.is_checked);
        item.is_checked = !is_checked;
      }
      return item;
    });
    setData(tab_data);
    const postData = {
      action: {
        _id: action._id,
        type: action.tool,
        options: action.growth_tool_data.statement_array.map((item) => ({
          option: item.option,
        })),
      },
      mission_schedule_id: schedule._id,
    };
    handleActionSubmit(postData);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
  };
  const openQuestionsPopup = (e, schedule) => {};

  const handleSubmitOnScreenActions = async (postData, schedule) => {};

  const onscreen_questions_data = (e, schedule) => {
    e.preventDefault();
    const requiredQuestion = schedule.schedule_questions.find(
      (item) => item.is_required && !item.answer_statement
    );

    if (requiredQuestion) {
      enqueueSnackbar("Please answer all the questions with *", {
        variant: "error",
      });
      return;
    }

    let postData = {
      created_for: "mission",
      schedule_id: schedule_id,
      other_module_id: schedule?._id,
      question_answer_array: schedule.schedule_questions.map((item) => ({
        _id: item._id,
        answer_statement: item.answer_statement,
      })),
    };
    handleSubmitOnScreenActions(postData, schedule);
  };

  useEffect(() => {
    if (localStorage.getItem("showQuestionsSreen") == 1) {
      setShowQuestionsSreen(1);
    } else if (localStorage.getItem("showQuestionsSreen") == 2) {
      setShowQuestionsSreen(2);
    }
    get_mission_detail();
  }, []);

  let breadCrumbMenu = [
    {
      title: "Mission Levels",
      navigation: `/mission-levels`,
    },
    {
      title:
        missionDetail?.membership_level_info?.badge_level_info?.tagline ||
        "Levels",
      navigation: `/mission-levels/${level_id}`,
    },
    {
      title: missionDetail.title,
      navigation: `/mission-levels/${level_id}/mission/${mission_id}`,
    },
    {
      title: data?.main_heading,
      navigation: ``,
      active: true,
    },
  ];

  console.log(isLoading, "isLoadingisLoadingisLoading");

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="mission-level-heading">
        <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
      </div>
      <div className="row">
        <div className="col-12">
          <>
            <DayVideoTab
              setOpenModal={setOpenModal}
              schedule={data}
              handleChangeAction={handleChangeAction}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              handleUnlock={handleClick}
              openQuestionsPopup={openQuestionsPopup}
              hideSubmitButton={hideSubmitButton}
              playedContent={true}
              setData={setData}
              played={played}
              missionContentSettings={missionContentSettings}
              onscreen_questions_data={onscreen_questions_data}
              isLoadingDataOnScreen={isLoadingDataOnScreen}
              setShowQuestionsSreen={setShowQuestionsSreen}
              missionResult={missionResult}
            />
          </>
        </div>
      </div>
    </div>
  );
}
